import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Button, IconButton, AppBar as MUIAppBar, alpha } from '@material-ui/core';
import NextLink from 'next/link';

import { media } from '@/styles';
import { mqMax } from '@/styles/breakpoints';

export const AppBar = styled(MUIAppBar, { shouldForwardProp: isPropValid })<{ shrink?: boolean, showfade?: boolean }>`
  background-image: ${({ theme, showfade }) => (showfade ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 80%);' : 'transparent')};
  background-color: ${({ theme, shrink }) => (shrink ? alpha(theme.palette.background.default, 1) : 'transparent')};
  transition: all 0.2s linear;
`;

export const Toolbar = styled.div<{ isExpanded?: boolean }>`
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: ${({ isExpanded }) => (isExpanded ? 'center' : 'space-between')};
  padding: 0 1rem;
  ${media.mdl} {
    padding: 0 4rem;
  }
`;

export const Logo = styled(NextLink, { shouldForwardProp: isPropValid })`
  display: block;
  height: 35px;
  margin: 24px 10px;
`;

export const LogoText = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-left: 1rem;
  letter-spacing: -0.6px;
  transition: opacity 0.2s linear;
`;

export const DragonContainer = styled.div`
  height: 36px;
  align-self: center;
  padding-bottom: 0.2rem;
  margin-left: 10px;

  ${mqMax[6]} {
    margin-left: 0;
  }
`;

export const LinkButton = styled.div`
  font-family: 'SunsetGothicProItalic';
  font-size: 32px;
`;

export const LinkButtonContent = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'inline-block' : 'block')};
  border-bottom: ${({ active }) => (active ? '1px solid white' : 0)};
  overflow: hidden; /* Hide any overflow content */
  height: 2.5rem;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledButton = styled(Button)<{ underline?: boolean }>`
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

export const LogoTitle = styled.div`
  margin-left: ${({ theme }) => theme.spacing(0, 2)};
`;

export const MenuButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  :hover {
    background-color: transparent;
    color: #EC6446;
  }

  ${mqMax[6]} {
    margin-left: 0;
  }

`;

export const DesktopVisible = styled.div`
  display: none;
  ${media.mdl} {
    display: inherit;
  }
`;
