import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { media } from '@/styles';

export const Wrapper = styled.div`
  width: 80%;
  text-align: center;
  margin: 0 auto;

  ${media.sm} {
    width: 50%;
  }
`;

export const Error = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
