import theme from '@/theme/theme';

export const media = {
  xs: theme.breakpoints.up('xs'), // 0px
  sm: theme.breakpoints.up('sm'), // 600px
  smm: theme.breakpoints.up('smm'), // 800px
  md: theme.breakpoints.up('md'), // 1024px
  mdl: theme.breakpoints.up('mdl'), // 1280px
  lg: theme.breakpoints.up('lg'), // 1440px
  xl: theme.breakpoints.up('xl'), // 1920px
};
