import { Box } from '@material-ui/core';
import { FC, useEffect, useMemo } from 'react';

import { useOffers } from '@/hooks/useOffers';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import { sortByRecurringPeriod } from '@/utils/offers';

import { Offer } from './Offer';

interface SelectOfferProps {
  includedIds: IOfferIds[];
  preSelectedId?: string;
  selectedId?: string;
  onSelectedChange: (id: IOfferItem) => void;
}

export const SelectOffer: FC<SelectOfferProps> = ({ includedIds, selectedId, preSelectedId, onSelectedChange }) => {
  const { offers, offersStylesConfig } = useOffers();

  const mappedOffers: IOfferItem[] = useMemo(
    () => (offers ? offers?.data.viewer.offers.edges.map((offer) => offer.node) : []),
    [offers]
  );

  const filteredOffers = useMemo<IOfferItem[]>(() => {
    if (!mappedOffers || !includedIds) {
      return [];
    }

    if (includedIds.length === 0) {
      return mappedOffers;
    }

    const _offerIds = includedIds.map((o) => o.offerId);

    const filteredOffers = mappedOffers.filter((offer) => _offerIds.includes(offer.id));

    const subscribeTypeOffers = filteredOffers
      ?.filter((offer) => offer.__typename === OfferType.SubscribeType)
      .sort(sortByRecurringPeriod);

    const passTypeOffers = filteredOffers?.filter((offer) => offer.__typename === OfferType.PassType);

    return [...subscribeTypeOffers, ...passTypeOffers];
  }, [mappedOffers, includedIds]);

  useEffect(() => {
    if (filteredOffers.length === 0) {
      return;
    }

    if (preSelectedId) {
      const offer = filteredOffers.find((o) => o.id === preSelectedId);
      if (offer) {
        onSelectedChange(offer);
      }
    } else {
      const firstOffer = filteredOffers[0];
      onSelectedChange(firstOffer);
    }
  }, [preSelectedId, filteredOffers, onSelectedChange]);

  return (
    <>
      {filteredOffers.map((offer) => (
        <Box mb={4} key={offer.id}>
          <Offer
            data={offer}
            checked={selectedId === offer.id}
            handleChange={() => onSelectedChange(offer)}
            offersStylesConfig={offersStylesConfig?.find((config) => config.magineOfferId === offer.id)}
          />
        </Box>
      ))}
    </>
  );
};
