import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { FC } from 'react';

import { Wrapper } from '@/components/SelectOffer/Offer/Offer.styles';
import { IOfferItem } from '@/models/IOffer.interface';
import { media } from '@/styles';
import { getOfferColor } from '@/utils/offers';

interface SelectedOfferProps {
  offer?: IOfferItem | null;
  reducedPrice?: number;
}

export const SelectedOffer: FC<SelectedOfferProps> = ({ offer, reducedPrice }) => {
  const hasReducedPrice = reducedPrice !== undefined;
  return (
    <Wrapper color={getOfferColor(offer?.recurringPeriod?.unit)}>
      <Title>{offer?.title}</Title>
      {offer && offer.priceInCents > 0 ? (
        <div>
          <PriceWrapper>
            <Price isDiscounted={hasReducedPrice}>
              {offer.priceInCents / 100} {offer.currency}
            </Price>
            {hasReducedPrice && (
              <Price>
                {reducedPrice > 0 ? reducedPrice / 100 : 0} {offer.currency}
              </Price>
            )}
          </PriceWrapper>
        </div>
      ) : (
        <Price>Gratis</Price>
      )}
    </Wrapper>
  );
};

const Title = styled.span`
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(0, 10)};

  ${media.md} {
    font-size: 24px;
    margin-bottom: 5px;
  }
`;

const Price = styled(Typography, { shouldForwardProp: isPropValid })<{ isDiscounted?: boolean }>`
  font-size: ${({ isDiscounted }) => (isDiscounted ? '11px' : '18px')};
  font-weight: bold;
  text-decoration: ${({ isDiscounted }) => (isDiscounted ? 'line-through' : 'unset')};

  ${media.md} {
    font-size: ${({ isDiscounted }) => (isDiscounted ? '14px' : '24px')};
  }
`;

export const PriceWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: baseline;
`;
