import { Box, TextField } from '@material-ui/core';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import EyeAdornment from '@/components/EyeAdornment/EyeAdornment';
import { Typography } from '@/components/Typography';
import { IAuthUser } from '@/models/IAuthUser.interface';

import { Error, TextButton } from '../../AuthorizationDialog.styles';
import { AuthForm, LoginSteps } from '../../AuthorizationDialog.types';
import styled from '@emotion/styled';

interface LoginStepProps {
  auth: IAuthUser;
  formEmail: string;
  setStep: Dispatch<SetStateAction<LoginSteps>>;
  control: Control<AuthForm>;
  errors: FieldErrors<AuthForm>;
  handleForgotPassword: () => void;
}

const FieldWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const LoginStep: FC<LoginStepProps> = ({ auth, formEmail, setStep, control, errors, handleForgotPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box>
      <Box mb={4} className="transition-element">
        <Typography variant="bodySmall">Du loggar in som:</Typography>
        <Typography variant="bodySmall">
          <strong>{formEmail}</strong>
        </Typography>
        <span>
          Inte din epost?&nbsp;
          <TextButton onClick={() => setStep(LoginSteps.EMAIL)}>
            <Typography variant="bodySmall">Gå tillbaka</Typography>
          </TextButton>

        </span>
      </Box>
      <Controller
        name="password"
        control={control}
        rules={{
          required: 'Obligatoriskt fält',
        }}
        render={({ field }) => (
          <FieldWrapper>
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              margin="dense"
              placeholder="Lösenord"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password?.message}
              data-testid="password-input"
              InputProps={{
                endAdornment: (
                  <EyeAdornment
                    handleClickShowPassword={toggleShowPassword}
                    handleMouseDownPassword={toggleShowPassword}
                    showPassword={showPassword}
                  />
                ),
              }}
            />
          </FieldWrapper>
        )}
      />
      {auth.error && (
        <Error variant="body2" color="error" data-testid="login-error-message">
          Felaktigt lösenord
        </Error>
      )}
      <Box textAlign="left">
        <TextButton onClick={handleForgotPassword}>
          <Typography variant="bodySmall">Glömt lösenordet?</Typography>
        </TextButton>
      </Box>
    </Box>
  );
};

export default LoginStep;
