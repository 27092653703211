import DragonLogo from '@/assets/icons/dragon-logo-white.svg';
import { mqMax } from '@/styles/breakpoints';
import styled from '@emotion/styled';

const DragonLogoImg = styled.img`
  margin-left: 1rem;
  ${mqMax[6]} {
    margin-right: 2rem;
    margin-left: 0;
  }
`;

const DragonLogoWhite = () => {
  return <DragonLogoImg src={DragonLogo.src} alt="dragon icon" style={{ width: '100%', height: '100%' }} />;
};

export default DragonLogoWhite;
