import { FC } from 'react';

interface LogoIconBlackProps {
  className?: string;
}

const LogoIconBlack: FC<LogoIconBlackProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      width="95"
      height="40"
      viewBox="0 0 178.58 166.39"
    >
      <title>draken-film</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="srgb_neg">
          <path
            fill="#000"
            d="M19.39,159.56V151a1.67,1.67,0,0,0-1.67-1.67,1.75,1.75,0,0,0-1.19.48L0,166.39H63.39L38.27,141.27A52.69,52.69,0,0,1,19.39,159.56Z"
          />
          <path
            fill="#000"
            d="M162.38,154.16,47.44,39.21a19.52,19.52,0,0,0,0,27.61L95.58,115a3.34,3.34,0,1,1-4.73,4.72L27.38,56.23a19.53,19.53,0,0,0,0,27.62L58.51,115a3.34,3.34,0,0,1-4.73,4.72L30.47,96.4a19.54,19.54,0,0,0,0,27.63l42.38,42.36h73.62l-21.78-21.78a52.66,52.66,0,0,1-13.58,9.68,3.41,3.41,0,0,1-1.47.35,3.35,3.35,0,0,1-1.5-6.34,46,46,0,0,0,16.27-13.42l31.52,31.51h22.65A18.88,18.88,0,0,0,162.38,154.16Z"
          />
          <path
            fill="#000"
            d="M164.87,105.23h6.66V98.58l7.05-7.06H167.75a18.87,18.87,0,0,0-13.41,5.56l-7.41,7.4a58.37,58.37,0,0,1-3,21.73Z"
          />
          <path
            fill="#000"
            d="M138.54,120.86A52.43,52.43,0,0,0,125,70.19l-13-13a3.34,3.34,0,1,1,4.73-4.73L136.43,72.2a19,19,0,0,0,4.43-22.61l-1.16,1.16a1.67,1.67,0,0,1-2.36,0l-7.48-7.47a3.38,3.38,0,0,1-1-2.37V39a3.31,3.31,0,0,0-1-2.37L114.44,23.17a3.35,3.35,0,0,1-1-2.37V17.11a3.26,3.26,0,0,0-1-2.35l-7.92-7.93A15,15,0,0,1,100.63,0a7.6,7.6,0,0,0,0,10.74,11.57,11.57,0,0,1,0,16.35L91.1,36.63a26,26,0,0,0,0,36.8Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIconBlack;
