export interface Page {
  slug?: string;
  label: string;
  isPrivate: boolean;
  displayInHeader?: boolean;
  placement: 'main' | 'footer' | 'none';
  href?: string;
}

export const pages: Page[] = [
  { slug: 'contact', label: 'Kontakt', isPrivate: false, placement: 'footer' },
  { slug: 'redaktionellt', label: 'Redaktionellt', isPrivate: false, placement: 'main', displayInHeader: true },
  { slug: 'mina-sidor/mitt-filmbibliotek', label: 'Mina sidor', isPrivate: true, placement: 'main', displayInHeader: false },
  { href: 'https://support.drakenfilm.se/', label: 'Hjälp', isPrivate: false, placement: 'footer' },
];
