import { useRouter } from 'next/router';
import { useMemo } from 'react';

const QueryParamName = 'query';
const CursorsParamName = 'cursors';

export const useSearchUrl = () => {
  const router = useRouter();

  const urlQuery = useMemo<string>(() => (router.query.query as string) ?? '', [router.query.query]);

  const urlCursors = useMemo<string[]>(() => {
    const cursorsParam = (router.query.cursors as string) || undefined;
    return cursorsParam ? cursorsParam.split(',') : [];
  }, [router.query.cursors]);

  const updateUrl = (query: string, endCursor?: string | null): void => {
    const url = new URL(window.location.href);
    if (query) {
      url.searchParams.set(QueryParamName, query);
    } else {
      url.searchParams.delete(QueryParamName);
    }
    if (endCursor) {
      url.searchParams.set(CursorsParamName, [...urlCursors, endCursor].toString());
    } else {
      url.searchParams.delete(CursorsParamName);
    }
    router.replace(url.href, url.href, { shallow: true });
  };

  return {
    urlQuery,
    urlCursors,
    updateUrl,
  };
};
