import { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';

import {
  GetCustomIdFullMovieQuery,
  GetCustomIdFullMovieQueryVariables,
  GetSearchQuery,
  GetSearchQueryVariables,
  useGetCustomIdFullMovieQuery,
  useGetSearchQuery,
} from '@/graphql/queries/__generated__/movie.generated';
import { isTypename } from '@/utils/typescript';

export const useGetCustomIdFullMovie = (
  variables?: GetCustomIdFullMovieQueryVariables,
  opts?: QueryHookOptions<GetCustomIdFullMovieQuery, GetCustomIdFullMovieQueryVariables>
) => {
  const { data, ...rest } = useGetCustomIdFullMovieQuery({ ...opts, variables });

  const movie = useMemo(() => {
    if (data?.viewer.viewableCustomId?.__typename === 'Movie') {
      return { isAuthenticated: data?.viewer.isAuthenticated, ...data.viewer.viewableCustomId };
    }
  }, [data]);

  return {
    movie,
    ...rest,
  };
};

export const getFilteredSearchMovies = (movies?: GetSearchQuery['viewer']['search']['edges'] | null) =>
  movies ? movies.map((edge) => edge?.node).filter(isTypename('Movie')) : [];

export const useSearchQuery = (
  variables?: GetSearchQueryVariables,
  opts?: QueryHookOptions<GetSearchQuery, GetSearchQueryVariables>
) => {
  const { data, ...rest } = useGetSearchQuery({ ...opts, variables });
  const movies = getFilteredSearchMovies(data?.viewer.search.edges);

  return {
    movies,
    pageInfo: data ? data.viewer.search.pageInfo : null,
    ...rest,
  };
};
