import { Box, TextField, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { forwardRef } from 'react';

import { media } from '@/styles';

import { Button, ClearButton, Wrapper } from './SearchInput.styles';

import { Typography } from '../Typography';
import SearchIconWhite from '../icons/new/SearchIconWhite';

interface SearchInputProps {
  expanded?: boolean;
  onButtonClick?: () => void;
  onSearch?: (query: string) => void;
  query?: string;
  onMobileClose?: () => void;
}
export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(
  ({ expanded, onButtonClick, query = '', onSearch, onMobileClose }, ref) => {
    const mdMatch = useMediaQuery(media.md);

    const onCloseClick = () => {
      onSearch?.('');
      if (!mdMatch) {
        onMobileClose?.();
      } else {
        onButtonClick?.();
      }
    };

    return (
      <Wrapper expanded={!!expanded} ref={ref}>
        <Button onClick={onButtonClick} disableRipple visible={!expanded}>
          <Box height="32px">
            <SearchIconWhite />
          </Box>
        </Button>

        {expanded && (
          <Box display="flex" flexDirection="column" mt="4rem" alignItems="center" justifyContent="center" width="100%">
            <TextField
              placeholder="Sök"
              onChange={(event) => onSearch?.(event.target.value)}
              value={query}
              fullWidth
              autoFocus
              variant="outlined"
            />
            <Box padding="2rem 0">
              <ClearButton disableRipple onClick={onCloseClick} visible={true}>
                <Close fontSize="small" />
                <Typography variant="labelMedium" customColor="#FFFFFF">
                  Stäng
                </Typography>
              </ClearButton>
            </Box>
          </Box>
        )}
      </Wrapper>
    );
  }
);

SearchInput.displayName = 'SearchInput';
