import axios from 'axios';
import { FC, PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';

import { useAccountState } from '@/context/accountContext';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOffer, IOfferItem } from '@/models/IOffer.interface';

export interface ConfigContextValue {
  offers?: IOfferItem[];
  pageOffersIds?: IOfferIds[];
  setPageOffersIds: (offerIds: IOfferIds[] | undefined) => void;
}

export const ConfigContext = createContext<ConfigContextValue | undefined>(undefined);
ConfigContext.displayName = 'ConfigContext';

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [offers, setOffers] = useState<IOfferItem[]>();
  const [pageOffersIds, setPageOffersIds] = useState<IOfferIds[]>();

  const { auth } = useAccountState();

  const getOffers = useCallback(
    async () =>
      await axios.get<IOffer>(`/api/offers/${auth.token ?? null}`).then((response) => {
        setOffers(response.data.data?.viewer.offers.edges.map((edge) => edge.node));
      }),
    [auth.token]
  );

  useEffect(() => {
    if (auth.token) getOffers();
  }, [auth.token, getOffers]);

  const value = {
    offers,
    pageOffersIds,
    setPageOffersIds,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
