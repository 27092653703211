import styled from '@emotion/styled';
import { Button, Dialog, DialogContent } from '@material-ui/core';

import LogoIcon from '@/components/icons/LogoIcon';
import { media } from '@/styles';
import { mqMax } from '@/styles/breakpoints';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
  }
`;

export const CloseButton = styled(Button)`
  ${({ theme }) => theme.palette.text.primary};

  :hover {
    background-color: transparent;
  }
`;

export const Content = styled(DialogContent)`
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing(16, 8, 0, 8)};
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 100%;
  width: 600px;

  ${mqMax[4]} {
    width: 100%;
    max-width: 100vw;
  }
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLogoIcon = styled(LogoIcon)`
  position: absolute;
  left: ${({ theme }) => theme.spacing(4)}px;
  top: ${({ theme }) => theme.spacing(1.5)}px;

  path {
    fill: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  position: absolute;
  height: 56px;
  width: 100%;
  padding: 4rem;
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};

  ${media.md} {
    background-color: transparent;
    position: unset;
    height: unset;
  }
`;
