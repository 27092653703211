import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';

import { media } from '@/styles';
import Link from 'next/link';

export const Title = styled(Typography)`
  text-align: center;
  font-size: 26px;
  margin: ${({ theme }) => theme.spacing(3, 0, 4)};
`;

export const Error = styled(Typography)`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const TextButton = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-decoration: none;
  // margin: 0 auto;
  padding: 0;
  margin-top: 1rem;
  position: relative;
  z-index: 1;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #EC6446;
    z-index: -1;
  }

  &:hover::before {
    height: 9px;
  }

`;

export const InfoWrapper = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  height: 80%;

  padding: ${({ theme }) => theme.spacing(10)}px;
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  text-align: center;
  display: grid;
  grid-template-rows: 2fr auto;
`;

export const InfoText = styled(Typography)`
  font-size: 24px;
`;

export const InfoWrapperButtons = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: ${({ theme }) => theme.spacing(4)}px;

  ${media.md} {
    display: flex;
    justify-content: space-between;
  }
`;
