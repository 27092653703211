import styled from '@emotion/styled';
import { Box, Button, Drawer, IconButton } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { FC, useEffect, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useAccountState } from '@/context/accountContext';
import { useGetBasicCollectionQuery } from '@/graphql/queries/__generated__/collection.generated';
import { Page } from '@/utils/pages';

import Categories from './drawer-steps/Categories';
import MainMenu from './drawer-steps/MainMenu';
import { set } from 'lodash';

export const enum DrawerMenuSteps {
  MAIN_MENU,
  CATEGORIES,
}

const GenrerCollectionId = '1680f2ca-6932-4154-939f-faeb02c5d356';
const CategoriesCollectionId = '4315ac0a-8f33-45d0-b336-c292882eeff0';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    right: 0,
    top: 0,
    height: '100%',
    minWidth: 420,
    maxWidth: 420,

    [theme.breakpoints.down('xs')]: {
      minWidth: '100vw',
      maxWidth: 'auto',
    },
  },

  content: {
    padding: theme.spacing(10, 8),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  menuButton: {
    top: 6,
    right: 15,
  },

  navLink: {
    fontSize: 18,
    textDecoration: 'none',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    fontFamily: 'SunsetGothicProItalic',
  },
}));

export const NavButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  font-family: 'SunsetGothicProItalic';
`;

export const NavButtonContent = styled.div<{ active: boolean }>`
  margin: ${({ theme }) => theme.spacing(0, 1)};
  display: ${({ active }) => (active ? 'inline-block' : 'block')};
  border-bottom: ${({ active }) => (active ? '1px solid white' : 0)};
  font-family: 'SunsetGothicProItalic';
`;

const DrawerContentWrapper = styled.div`
  height: 100%;
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .menu-item {
    animation: slideIn 0.3s ease forwards;
    animation-delay: 0.3s;
    opacity: 0;
  }
`;

interface MenuProps {
  pages: Page[];
  toggle: (value: boolean) => void;
  open: boolean;
  footerNav: Page[];
}

export interface GenrerMenuItem {
  label: string;
  magineId: string;
}

const DrawerMenu: FC<MenuProps> = ({ pages, toggle, open, footerNav }) => {
  const [step, setStep] = useState<DrawerMenuSteps>(DrawerMenuSteps.MAIN_MENU);
  const drawerContentRef = useRef<HTMLDivElement>(null);
  const { auth } = useAccountState();
  const [genrerMenuItems, setGenrerMenuItems] = useState<GenrerMenuItem[]>([]);
  const [categoriesMenuItems, setCategoriesMenuItems] = useState<GenrerMenuItem[]>([]);
  const [genresTitle, setGenresTitle] = useState<string>('');
  const [categoriesTitle, setCategoriesTitle] = useState<string>('');

  const { data: genrerData } = useGetBasicCollectionQuery({
    variables: { id: GenrerCollectionId as string, first: 20 },
    context: { authToken: auth.token, clientIp: '' },
  });

  const { data: categoriesData } = useGetBasicCollectionQuery({
    variables: { id: CategoriesCollectionId as string, first: 20 },
    context: { authToken: auth.token, clientIp: '' },
  });

  useEffect(() => {
    if (genrerData) {
      const mappedGenrerData =
        genrerData?.viewer?.block?.__typename === 'LinkCollection'
          ? genrerData.viewer.block?.links?.edges?.map((edge) => ({
              label: edge?.node.title ?? '',
              magineId: edge?.node.magineId ?? '',
            }))
          : [];
      setGenresTitle((genrerData.viewer?.block as any)?.title ?? '');
      setGenrerMenuItems(mappedGenrerData ?? []);
    }
  }, [genrerData]);

  useEffect(() => {
    if (categoriesData) {
      const mappedCategoriesData =
        categoriesData?.viewer?.block?.__typename === 'LinkCollection'
          ? categoriesData.viewer.block?.links?.edges?.map((edge) => ({
              label: edge?.node.title ?? '',
              magineId: edge?.node.magineId ?? '',
            }))
          : [];
      setCategoriesTitle((categoriesData.viewer?.block as any)?.title ?? '');
      setCategoriesMenuItems(mappedCategoriesData ?? []);
    }
  }, [categoriesData]);

  const classes = useStyles();

  const moveToCategories = () => {
    setStep(DrawerMenuSteps.CATEGORIES);
  };

  const moveToMainMenu = () => {
    setStep(DrawerMenuSteps.MAIN_MENU);
  };
  const onClose = () => {
    toggle(false);
    setStep(DrawerMenuSteps.MAIN_MENU);
  };
  const stepComponents = {
    [DrawerMenuSteps.MAIN_MENU]: (
      <MainMenu onClose={onClose} pages={pages} auth={auth} moveToCategories={moveToCategories} footerNav={footerNav} />
    ),
    [DrawerMenuSteps.CATEGORIES]: (
      <Categories 
        moveToMainMenu={moveToMainMenu} 
        genrerMenuItems={genrerMenuItems} 
        genresTitle={genresTitle}
        categoriesMenuItems={categoriesMenuItems} 
        categoriesTitle={categoriesTitle}
        onClose={onClose} 
      />
    ),
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box data-testid="mobile-menu-content" className={classes.root}>
        <Box className={classes.content}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton edge="start" color="inherit" aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <DrawerContentWrapper>
            <SwitchTransition>
              <CSSTransition
                key={step}
                nodeRef={drawerContentRef}
                addEndListener={(done) => {
                  drawerContentRef.current?.addEventListener('transitionend', done, false);
                }}
                classNames="slide-and-fade"
              >
                <div ref={drawerContentRef} style={{ height: '100%' }}>
                  <div className="transition-element" style={{ height: '100%' }}>
                    {stepComponents[step]}
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </DrawerContentWrapper>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
