import { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';

interface IDailyChat {
  isActive: boolean;
  date?: Date;
}

const initialState: Record<'preTalkState' | 'movieState' | 'afterTalkState' | 'countdownState', IDailyChat> = {
  preTalkState: {
    isActive: false,
    date: new Date(),
  },
  movieState: {
    isActive: false,
    date: new Date(),
  },
  afterTalkState: {
    isActive: false,
    date: new Date(),
  },
  countdownState: {
    isActive: true,
    date: new Date(),
  },
};

export enum DailyChatActionTypes {
  DAILY_CHAT_PRE_TALK = 'DAILY_CHAT_PRE_TALK',
  DAILY_CHAT_MOVIE = 'DAILY_CHAT_MOVIE',
  DAILY_CHAT_AFTER_TALK = 'DAILY_CHAT_AFTER_TALK',
  DAILY_CHAT_COUNTDOWN = 'DAILY_CHAT_COUNTDOWN',
  DAILY_CHAT_CLOSE = 'DAILY_CHAT_CLOSE',
}

type Action =
  | {
      type: DailyChatActionTypes.DAILY_CHAT_PRE_TALK;
      payload: { isActive: boolean; date: Date };
    }
  | {
      type: DailyChatActionTypes.DAILY_CHAT_MOVIE;
      payload: { isActive: boolean; date: Date };
    }
  | {
      type: DailyChatActionTypes.DAILY_CHAT_AFTER_TALK;
      payload: { isActive: boolean; date: Date };
    }
  | {
      type: DailyChatActionTypes.DAILY_CHAT_COUNTDOWN;
      payload: { isActive: boolean; date: Date };
    }
  | {
      type: DailyChatActionTypes.DAILY_CHAT_CLOSE;
    };

interface State {
  preTalkState: IDailyChat;
  movieState: IDailyChat;
  afterTalkState: IDailyChat;
  countdownState: IDailyChat;
}

type Dispatch = (action: Action) => void;

const DailyChatStateContext = createContext<State | undefined>(undefined);
const DailyChatDispatchContext = createContext<Dispatch | undefined>(undefined);

function dailyChatReducer(state: State, action: Action): State {
  switch (action.type) {
    case DailyChatActionTypes.DAILY_CHAT_PRE_TALK:
      return {
        ...state,
        preTalkState: {
          isActive: action.payload.isActive,
          date: action.payload.date,
        },
      };
    case DailyChatActionTypes.DAILY_CHAT_MOVIE:
      return {
        ...state,
        movieState: {
          isActive: action.payload.isActive,
          date: action.payload.date,
        },
      };
    case DailyChatActionTypes.DAILY_CHAT_AFTER_TALK:
      return {
        ...state,
        afterTalkState: {
          isActive: action.payload.isActive,
          date: action.payload.date,
        },
      };

    case DailyChatActionTypes.DAILY_CHAT_COUNTDOWN:
      return {
        ...state,
        countdownState: {
          isActive: action.payload.isActive,
        },
      };

    case DailyChatActionTypes.DAILY_CHAT_CLOSE:
      return {
        countdownState: { isActive: false, date: state.countdownState.date },
        afterTalkState: { isActive: false, date: state.afterTalkState.date },
        preTalkState: { isActive: false, date: state.preTalkState.date },
        movieState: { isActive: false, date: state.movieState.date },
      };

    default:
      return state;
  }
}

type DailyChatProviderProps = PropsWithChildren;

const DailyChatProvider: FC<DailyChatProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(dailyChatReducer, initialState);

  return (
    <DailyChatStateContext.Provider value={state}>
      <DailyChatDispatchContext.Provider value={dispatch}>{children}</DailyChatDispatchContext.Provider>
    </DailyChatStateContext.Provider>
  );
};

function useDailyChatState() {
  const context = useContext(DailyChatStateContext);

  if (context === undefined) {
    throw new Error('[useDailyChatState] must be used within [DailyChatProvider]');
  }

  return context;
}

function useDailyChatDispatch() {
  const context = useContext(DailyChatDispatchContext);

  if (context === undefined) {
    throw new Error('[useDailyChatDispatch] must be used within [DailyChatProvider]');
  }

  return context;
}

export { DailyChatProvider, useDailyChatState, useDailyChatDispatch };
