import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import cookie from 'cookie';
import { sv } from 'date-fns/locale';
import App, { AppContext } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CookieBanner from '@/components/CookieBanner';
import Footer from '@/components/Footer';
import { GlobalSearchOverlay } from '@/components/GlobalSearchOverlay';
import { NavBar } from '@/components/NavBar';
import { NavigationProvider } from '@/context/NavigationContext';
import { AccountProvider } from '@/context/accountContext';
import { DialogProvider } from '@/context/authorizationDialogContext';
import { BookmarksProvider } from '@/context/bookmarks/bookmarks.provider';
import { ConfigProvider } from '@/context/config/config.provider';
import { DailyChatProvider } from '@/context/dailyChatContext';
import { SubscriptionProvider } from '@/context/subscription/subscription.provider';
import { useApollo } from '@/graphql/client';
import { GlobalStyles } from '@/styles';
import '@/styles/global.css';
import theme from '@/theme/theme';
import { globalSettings, pageView } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

export const META_TITLE =
  'Streama hundratals handplockade filmer från hela världen | Göteborg Film Festival - Draken Film';
export const META_DESCRIPTION = 'Streama hundratals filmer och se utvalda premiärer på bio för endast 89 kr/månad.';
// @ts-expect-error Typing it would be too complicated, postponed for now
function DrakenFilmApp({ Component, pageProps, authenticated, cookieconsent }) {
  // const classes = useStyles();
  const router = useRouter();
  const apolloClient = useApollo(pageProps);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { hide_header } = router.query;
  const { route } = router;

  const hiddenUiUrls = ['/75f8950dfd62'].includes(route);

  const hideHeader = hide_header === 'true' || hiddenUiUrls;
  const hiddenFooter = hiddenUiUrls;

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    if (authenticated) globalSettings(JSON.parse(authenticated).userId, 'TRUE');
    else globalSettings('', 'FALSE');
  }, [authenticated]);

  useEffect(() => {
    pageView(getMeta('pageType'));
  }, [pageProps]);

  const parsedAuthenticated = useMemo(() => JSON.parse(authenticated), [authenticated]);

  return (
    <>
      <Head>
        <title>{META_TITLE}</title>
        <meta name="facebook-domain-verification" content="nwnudzeed5ysa9slg9e9f1ehcskldw" />
        <meta name="description" content={META_DESCRIPTION} key="description" />
        <meta itemProp="name" content={META_TITLE} key="itemProp:name" />
        <meta itemProp="description" content={META_DESCRIPTION} key="itemProp:description" />

        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:title" content={META_TITLE} key="og:title" />
        <meta property="og:description" content={META_DESCRIPTION} key="og:description" />

        <meta name="twitter:title" content={META_TITLE} key="twitter:title" />
        <meta name="twitter:description" content={META_DESCRIPTION} key="twitter:description" />
        <meta name="twitter:site" content="@drakenfilm" key="twitter:url" />

        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <AccountProvider authenticated={parsedAuthenticated}>
                <NavigationProvider>
                  <ConfigProvider>
                    <DialogProvider>
                      <DailyChatProvider>
                        <SubscriptionProvider>
                          <BookmarksProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={sv}>
                              <>
                                <CssBaseline />
                                {!hideHeader && <NavBar />}
                                <GlobalSearchOverlay clientIp={pageProps.clientIp} authToken={authenticated?.token} />

                                <main>
                                  <Component {...pageProps} />
                                </main>
                                {!cookieconsent && <CookieBanner />}
                                {!hiddenFooter && <Footer />}
                              </>
                            </MuiPickersUtilsProvider>
                          </BookmarksProvider>
                        </SubscriptionProvider>
                      </DailyChatProvider>
                    </DialogProvider>
                  </ConfigProvider>
                </NavigationProvider>
              </AccountProvider>
              <ToastContainer />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </ApolloProvider>
    </>
  );
}

DrakenFilmApp.getInitialProps = async (appContext: AppContext) => {
  let authenticated = null;
  let cookieconsent = false;
  const request = appContext.ctx.req;

  if (request) {
    const cookies = cookie.parse(request.headers.cookie ?? '');
    if (!!cookies.version && cookies.version === process.env.versionKey) {
      authenticated = cookies.auth ? cookies.auth : null;
    }
    cookieconsent = !!cookies.df_cc;
  }

  // Call the page's `getInitialProps` and fill `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, authenticated, cookieconsent };
};

export default DrakenFilmApp;
