import { useCallback, useEffect, useState } from 'react';

import { getFilteredSearchMovies, useSearchQuery } from '@/graphql/hooks/movies';
import { movieImpression, movieSearch } from '@/utils/analytics';
import { DEFAULT_LIMIT } from '@/utils/search';

interface UseSearch {
  query?: string;
  clientIp?: string;
  initialMovies?: ReturnType<typeof getFilteredSearchMovies>;
  initialHasNextPage?: boolean;
  initialEndCursor?: string | null;
  authToken?: string;
  fetchOnChange?: boolean;
}
export const useSearch = ({
  query,
  clientIp = '',
  initialMovies,
  initialEndCursor,
  initialHasNextPage,
  authToken,
  fetchOnChange = false,
}: UseSearch) => {
  const [results, setResults] = useState(initialMovies);
  const [hasNextPage, setHasNextPage] = useState(initialHasNextPage);
  const [endCursor, setEndCursor] = useState(initialEndCursor);
  const [isFetching, setIsFetching] = useState(false);

  const { fetchMore } = useSearchQuery(
    {
      query: query ?? '',
      first: DEFAULT_LIMIT,
    },
    { context: { authToken, clientIp }, skip: true }
  );

  const fetchSearchResults = useCallback(
    async (query?: string, after?: string | string[] | null) => {
      if (!query) {
        return;
      }
      setIsFetching(true);

      const { data } = await fetchMore({
        variables: { query, after },
        context: { authToken, clientIp },
      });

      const _movies = data.viewer.search.edges ? getFilteredSearchMovies(data.viewer.search.edges) : [];
      const _hasNextPage = data.viewer.search.pageInfo.hasNextPage;
      const _endCursor = data.viewer.search.pageInfo.endCursor;

      if (_movies && _movies.length > 0) {
        setResults((prevState) => {
          const updatedMovies = after ? [...(prevState ?? []), ..._movies] : _movies;
          movieSearch(query ?? '', updatedMovies);
          movieImpression(updatedMovies);
          return updatedMovies;
        });
        setHasNextPage(_hasNextPage);
        setEndCursor(_endCursor);
      } else {
        setResults([]);
      }

      setIsFetching(false);
    },
    [authToken, clientIp, fetchMore]
  );

  // clear result when query is empty
  useEffect(() => {
    if (!query && fetchOnChange) {
      setResults([]);
    }
  }, [fetchOnChange, query]);

  // decide whether search work on input change on click
  useEffect(() => {
    if (!fetchOnChange) {
      return;
    }
    fetchSearchResults(query);
  }, [fetchOnChange, fetchSearchResults, query]);

  return {
    results,
    isFetching,
    hasNextPage,
    endCursor,
    fetchSearchResults,
  };
};
