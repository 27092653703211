import { css } from '@emotion/react';

export const transitionStyles = css`
  .slide-and-fade-enter .transition-element {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-and-fade-enter-active .transition-element {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-and-fade-exit .transition-element {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-and-fade-exit-active .transition-element {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-and-fade-enter-active .transition-element,
  .slide-and-fade-exit-active .transition-element {
    transition: opacity 300ms, transform 200ms;
  }

  .fade-enter {
    opacity: 0;
    transform: translateY(-50%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 400ms, transform 200ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 400ms, transform 200ms;
  }
`;
