import { FC } from 'react';

import { Typography } from './Typography';

interface PaymentErrorMessageProps {
  error: boolean;
}

const PaymentErrorMessage: FC<PaymentErrorMessageProps> = ({ error }) => {
  if (!error) return null;

  return <Typography variant="bodySmall">Ogiltig kampanjkod</Typography>;
};

export default PaymentErrorMessage;
