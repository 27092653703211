import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FC } from 'react';

interface EyeAdornmentProps {
  handleClickShowPassword: () => void;
  handleMouseDownPassword: () => void;
  showPassword: boolean;
}

const EyeAdornment: FC<EyeAdornmentProps> = ({ handleClickShowPassword, handleMouseDownPassword, showPassword }) => {
  return (
    <InputAdornment position="end">
      <IconButton
        size="small"
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
      </IconButton>
    </InputAdornment>
  );
};

export default EyeAdornment;
