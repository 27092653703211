import { TextField, TextFieldProps } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';

import { Error, Wrapper } from './PromoCodeFields.styles';

interface PromoCodeFieldProps {
  className?: string;
  promoCode?: string;
  onSetValue: (promoCode: string) => void;
}

export const PromoCodeField: FC<PromoCodeFieldProps & TextFieldProps> = ({
  className,
  onSetValue,
  promoCode,
  ...rest
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const promoCode = event.target.value.replace(/\s/g, '');
    onSetValue?.(promoCode.toUpperCase());
  };
  return (
    <Wrapper>
      <TextField
        {...rest}
        className={className}
        margin="dense"
        variant="outlined"
        id="code"
        name="code"
        placeholder="Ange eventuell kampanjkod"
        fullWidth
        autoComplete="off"
        value={promoCode}
        onChange={handleInputChange}
      />
      {rest.error && (
        <Error variant="body2" color="error">
          Ogiltig kod.
        </Error>
      )}
    </Wrapper>
  );
};
