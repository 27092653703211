import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { ButtonBase } from '@material-ui/core';

import { media } from '@/styles';

export const Wrapper = styled.div<{ expanded?: boolean }>`
  position: ${({ expanded }) => (expanded ? 'static' : 'relative')};
  height: 100%;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: ${({ expanded }) => (expanded ? '100%' : '42px')};
  background-color: transparent;
  transition: ${({ expanded }) => `width 0.2s ease-in-out ${!expanded ? '0.3s' : '0s'}`};
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  will-change: width, background-color, height;
  max-width: 100%;
  ${media.md} {
    position: relative;
    height: auto;
    width: ${({ expanded }) => (expanded ? '520px' : '42px')};
    transition: width 0.2s ease-in-out;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  height: 100%;
  width: 100%;
  outline: none;
  color: white;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const Button = styled(ButtonBase, { shouldForwardProp: isPropValid })<{ visible?: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const ClearButton = styled(ButtonBase, { shouldForwardProp: isPropValid })<{ visible: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
