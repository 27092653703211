import { Box, FormControl, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useRouter } from 'next/router';
import { FC, FormEvent } from 'react';

import { Button } from '@/components/Button/Button';
import { useAccountState } from '@/context/accountContext';
import { cancelSubscriptionClick } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Radikal-medium',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

interface PaymentCancelDialogProps {
  offerId?: string;
}

export const PaymentCancelDialog: FC<PaymentCancelDialogProps> = ({ offerId }) => {
  const classes = useStyles();
  const router = useRouter();
  const account = useAccountState();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await axios.post(`/api/auth/subscription/cancel/${offerId}`, {
      token: account.auth.token,
    });

    cancelSubscriptionClick(getMeta('pageType'));
    router.reload();
  };

  return (
    <>
      <Box mb={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2" className={classes.title}>
          Är du säker på att du vill avsluta din prenumeration?
        </Typography>
      </Box>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <Button type="submit" size="large" color="red">
            Avsluta Prenumeration
          </Button>
        </FormControl>
      </form>
    </>
  );
};
