import axios from 'axios';
import Router, { useRouter } from 'next/router';
import { useState } from 'react';

import { accountActionTypes, useAccountDispatch } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { ICampaignResponse } from '@/models/ICampaign.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { ISubscription } from '@/models/ISubscription.interface';
import { userLogin, userRegistration } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

import { LoginSteps } from './AuthorizationDialog.types';

interface UseAuthorizationDialogProps {
  emailQuery?: string;
  auth?: IAuthUser;
  canBuyHavingOffer?: boolean;
  skipOffers?: boolean;
  selectedOffer?: IOfferItem | null;
}

export const useAuthorizationDialog = ({
  emailQuery,
  auth,
  canBuyHavingOffer,
  skipOffers,
  selectedOffer,
}: UseAuthorizationDialogProps) => {
  const [step, setStep] = useState<LoginSteps>(emailQuery ?? auth?.token ? LoginSteps.USER_EXISTS : LoginSteps.EMAIL);
  const [hasOffer, setHasOffer] = useState(false);
  const dialogDispatch = useDialogDispatch();
  const router = useRouter();
  const { dispatch: accountDispatch } = useAccountDispatch();

  const validatePromoCode = async (offerId: string, promoCode: string) => {
    if (!offerId) {
      throw new Error('Empty offer id');
    }

    if (promoCode) {
      const { data: campaignResponse } = await axios.post<ICampaignResponse>('/api/campaign', {
        promoCode,
        token: auth?.token,
      });

      if (campaignResponse.errors ?? !campaignResponse.data?.viewer.campaign?.offers.edges.length) {
        return { status: 'invalid' };
      }
      if (!campaignResponse.data?.viewer.campaign?.offers.edges.some((offer) => offer.node.id === offerId)) {
        return { status: 'wrong-offer', offer: campaignResponse.data?.viewer.campaign?.offers.edges[0].node };
      }
      return { status: 'valid' };
    }

    return null;
  };

  const logInUser = async (identity: string, accessKey: string) => {
    accountDispatch({ type: accountActionTypes.ERROR, payload: false });
    const { data } = await axios.post<IAuthUser>('/api/auth/login', {
      identity,
      accessKey,
    });

    if (!data.error) {
      const { data: userdata } = await axios.get(`/api/auth/users/${data.userId}`);

      userLogin(getMeta('pageType'));
      accountDispatch({ type: accountActionTypes.LOGIN, payload: data });
      accountDispatch({ type: accountActionTypes.USERDATA, payload: userdata });

      await axios.post<boolean>('/api/auth/cookie', { name: 'auth', value: JSON.stringify(data) });
      await axios.post<boolean>('/api/auth/cookie', { name: 'version', value: process.env.versionKey });
      const result = await axios.post<ISubscription>(
        `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/subscription/${data.sessionId}`
      );

      const isActiveSubscriber =
        result.data.data.viewer.entitlements.edges.filter(
          (sub) =>
            sub.node.__typename === 'EntitlementPassType' ||
            ((sub.node.status === 'active' || sub.node.status === 'cancelled') &&
              sub.node.__typename === 'EntitlementSubscribeType')
        ).length > 0;

      if ((isActiveSubscriber && !canBuyHavingOffer) || skipOffers) {
        setHasOffer(true);
        dialogDispatch({ type: dialogActionTypes.CLOSE_ALL });

        if(router.pathname === '/')
          router.push('/filmer');
        else
          router.reload();
        
        return;
      }
      setStep( LoginSteps.SELECT_OFFER );
    } else {
      accountDispatch({
        type: accountActionTypes.ERROR,
        payload: data.error,
      });
    }
  };

  const registerUser = async (email: string, password: string, marketing: boolean) => {
    const { data: register } = await axios.post<IAuthUser>(`/api/auth/register`, {
      email,
      password,
      marketing,
    });

    if (register.newUser) {
      userRegistration(getMeta('pageType'));

      const { data: userdata } = await axios.get(`/api/auth/users/${register.userId}`);

      accountDispatch({ type: accountActionTypes.LOGIN, payload: register });
      accountDispatch({ type: accountActionTypes.USERDATA, payload: userdata });

      await axios.post<boolean>(`/api/auth/cookie`, { name: 'auth', value: JSON.stringify(register) });
      await axios.post<boolean>(`/api/auth/cookie`, { name: 'version', value: process.env.versionKey });
      if (skipOffers) {
        setHasOffer(true);
        dialogDispatch({ type: dialogActionTypes.CLOSE_ALL });
        Router.reload();
      } else {
        setStep(LoginSteps.SELECT_OFFER);
      }
    }
  };

  return { validatePromoCode, logInUser, registerUser, step, setStep, hasOffer };
};
