import { Box } from '@material-ui/core';
import { FC } from 'react';

import LinkButton from '@/components/Button/LinkButton';
import { Typography } from '@/components/Typography';

import { GenrerMenuItem } from '../DrawerMenu';

interface CategoriesProps {
  moveToMainMenu: () => void;
  genrerMenuItems: GenrerMenuItem[];
  categoriesMenuItems: GenrerMenuItem[];
  genresTitle: string;
  categoriesTitle: string;
  onClose: () => void;
}

const Categories: FC<CategoriesProps> = ({ moveToMainMenu, genrerMenuItems, categoriesMenuItems, genresTitle, categoriesTitle, onClose }) => {
  return (
    <div>
      <LinkButton onClick={moveToMainMenu} fontStyle="normal" arrowPlacement="left" fontWeight="400">
        Tillbaka
      </LinkButton>
      <Box mt="4rem">
        <Box mb="2rem">
          <Typography variant="bodyExtraSmall" customColor="#8E8E8E">
            {genresTitle}
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {genrerMenuItems.map((item) => (
            <Box key={item.magineId} width="50%" alignItems="flex-start" mt="1rem" mb="1rem">
              <LinkButton path={`/kollektion/${item.magineId}`} onClick={onClose} fontStyle="normal">
                {item.label}
              </LinkButton>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt="4rem">
        <Box mb="2rem">
          <Typography variant="bodyExtraSmall" customColor="#8E8E8E">
            {categoriesTitle}
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {categoriesMenuItems && categoriesMenuItems.map((item) => (
            <Box key={item.magineId} width="50%" alignItems="flex-start" mt="1rem" mb="1rem">
              <LinkButton path={`/kollektion/${item.magineId}`} onClick={onClose} fontStyle="normal">
                {item.label}
              </LinkButton>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default Categories;
