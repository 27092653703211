import { css } from '@emotion/react';

import { mqMax, mqMin } from '@/styles/breakpoints';

export const variantsStyles = {
  bodyExtraSmall: css`
    line-height: 140%;
    font-size: 13px;
    ${mqMax[1]} {
      font-size: 13px;
    }
    ${mqMax[2]} {
      font-size: 13px;
    }
    ${mqMax[3]} {
      font-size: 10px;
    }
    ${mqMax[4]} {
      font-size: 10px;
    }
  `,
  bodySmall: css`
    line-height: 140%;
    font-size: 16px;
  `,
  bodyMedium: css`
    line-height: 140%;
    font-size: 18px;

    ${mqMax[2]} {
      font-size: 18px;
    }
    ${mqMax[3]} {
      font-size: 16px;
    }
    ${mqMax[4]} {
      font-size: 16px;
    }
  `,
  bodyLarge: css`
    line-height: 140%;
    font-size: 24px;

    ${mqMax[2]} {
      font-size: 24px;
    }
    ${mqMax[3]} {
      font-size: 24px;
    }
    ${mqMax[4]} {
      font-size: 19px;
    }
  `,
  bodyExtraLarge: css`
    line-height: 140%;
    font-size: 38px;

    ${mqMax[2]} {
      font-size: 2.64vw;
    }
    ${mqMax[5]} {
      font-size: 29px;
    }
    ${mqMax[3]} {
      font-size: 29px;
    }
  `,
  displaySmall: css`
    line-height: 1.1;
    font-size: 170px;

    ${mqMax[1]} {
      font-size: 170px;
    }
    ${mqMax[2]} {
      font-size: 170px;
    }
    ${mqMax[6]} {
      font-size: 120px;
    }
    ${mqMax[3]} {
      font-size: 100px;
    }
    ${mqMax[4]} {
      font-size: 50px;
    }
  `,
  displayMedium: css`
    line-height: 1;
    font-size: 160px;

    ${mqMax[1]} {
      font-size: 160px;
    }
    ${mqMax[2]} {
      font-size: 160px;
    }
    ${mqMax[3]} {
      font-size: 130px;
    }
    ${mqMax[4]} {
      font-size: 68px;
    }
  `,
  displayLarge: css`
    line-height: 1.1;
    font-size: 200px;

    ${mqMax[1]} {
      font-size: 200px;
    }
    ${mqMax[2]} {
      font-size: 200px;
    }
    ${mqMax[3]} {
      font-size: 130px;
    }
    ${mqMax[4]} {
      font-size: 55px;
    }
  `,
  displayLargeDynamic: css`
    line-height: 1.1;
    font-size: 14.5vw;
    letter-spacing: -0.7vw;
    ${mqMin[1]} {
      font-size: 280px;
      letter-spacing: -12px;
    }
  `,
  headlineXSmall: css`
    line-height: 1.1;
    font-size: 55px;

    ${mqMax[3]} {
      font-size: 50px;
    }
    ${mqMax[4]} {
      font-size: 30px;
    }
  `,
  headlineSmall: css`
    line-height: 1.1;
    font-size: 70px;

    ${mqMax[2]} {
      font-size: 70px;
    }
    ${mqMax[3]} {
      font-size: 50px;
    }
    ${mqMax[4]} {
      font-size: 30px;
    }
  `,
  headlineSmallMedium: css`
    line-height: 1.1;
    font-size: 90px;

    ${mqMax[2]} {
      font-size: 90px;
    }
    ${mqMax[3]} {
      font-size: 60px;
    }
    ${mqMax[4]} {
      font-size: 40px;
    }
  `,
  headlineMedium: css`
    line-height: 1.1;
    font-size: 120px;

    ${mqMax[2]} {
      font-size: 120px;
    }
    ${mqMax[3]} {
      font-size: 80px;
    }
    ${mqMax[4]} {
      font-size: 50px;
    }
  `,
  headlineLarge: css`
    line-height: 1.1;
    font-size: 130px;

    ${mqMax[2]} {
      font-size: 130px;
    }
    ${mqMax[3]} {
      font-size: 165px;
    }
    ${mqMax[4]} {
      font-size: 106px;
    }
  `,
  headlineLargeDynamic: css`
    line-height: 0.9;
    font-size: 175px;
    letter-spacing: -3px;

    ${mqMax[1]} {
      font-size: 9vw;
    }

    ${mqMax[2]} {
      font-size: 130px;
    }
    ${mqMax[3]} {
      font-size: 16.6vw;
    }
    ${mqMax[4]} {
      font-size: 25vw;
    }
  `,
  labelFooter: css`
    line-height: 1.1;
    font-size: 16px;
    // ok
  `,
  labelSmall: css`
    line-height: 1.1;
    font-size: 13px;
    // ok
  `,
  labelMedium: css`
    line-height: 1.1;
    font-size: 16px;
  `,
  labelLarge: css`
    line-height: 1.1;
    font-size: 16px;
  `,
  titleSmall: css`
    line-height: 1.1;
    font-size: 16px;
    letter-spacing: .1rem;
  `,
  titleMedium: css`
    line-height: 1.1;
    font-size: 30px;
    ${mqMax[1]} {
      font-size: 30px;
    }
    ${mqMax[2]} {
      font-size: 30px;
    }
    ${mqMax[3]} {
      font-size: 18px;
    }
    ${mqMax[4]} {
      font-size: 18px;
    }
  `,
  titleLarge: css`
    line-height: 1.1;
    font-size: 40px;

    ${mqMax[3]} {
      font-size: 28px;
    }
    ${mqMax[4]} {
      font-size: 20px;
    }
  `
};
