import { IOfferItem } from '@/models/IOffer.interface';

export function sortByRecurringPeriod(a: IOfferItem, b: IOfferItem): number {
  const order: Record<'MONTHLY' | 'YEARLY', number> = { MONTHLY: 0, YEARLY: 1 };

  const unitA = order[a.recurringPeriod.unit];
  const unitB = order[b.recurringPeriod.unit];

  return unitA - unitB;
}

export const getOfferColor = (recurringPeriod?: 'MONTHLY' | 'YEARLY') => {
  if (recurringPeriod === 'YEARLY') {
    return '#54211A';
  } else if (recurringPeriod === 'MONTHLY') {
    return '#1D3447';
  } else {
    return '#213B2F';
  }
};
