import { css } from '@emotion/react';
import { Box, FormControl } from '@material-ui/core';
import axios from 'axios';
import { FC, FormEvent, useCallback, useState } from 'react';

import { Button } from '@/components/Button/Button';
import Payment from '@/components/Payment';
import PaymentErrorMessage from '@/components/PaymentErrorMessage';
import { PromoCodeField } from '@/components/PromoCodeField';
import { SelectOffer } from '@/components/SelectOffer';
import { Typography } from '@/components/Typography';
import { useAccountState } from '@/context/accountContext';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { OfferType } from '@/models/OfferTypeName.enum';

import { SelectedOffer } from './SelectedOffer';

import { useAuthorizationDialog } from '../AuthorizationDialog.hooks';
import { InfoWrapper, InfoWrapperButtons } from '../AuthorizationDialog.styles';

interface BuyOfferDialogProps {
  typename?: OfferType;
  offerIds: IOfferIds[];
  selectedOffer: IOfferItem | null;
  onSelectOffer: (offer: IOfferItem) => void;
}

enum BuyOfferStep {
  SelectOffer,
  Payment,
}

export const BuyOfferDialogStep: FC<BuyOfferDialogProps> = ({ offerIds, selectedOffer, onSelectOffer }) => {
  const { auth } = useAccountState();
  const { validatePromoCode } = useAuthorizationDialog({ auth, selectedOffer });

  const [promoCode, setPromoCode] = useState<string>();
  const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [step, setStep] = useState<BuyOfferStep>(BuyOfferStep.SelectOffer);
  const [reducedPrice, setReducedPrice] = useState<number>();

  const [promoCodeOffer, setPromoCodeOffer] = useState<IOfferItem | null>(null);

  const handlePaymentError = useCallback(() => {
    setPaymentError(true);
    setStep(BuyOfferStep.SelectOffer);
  }, []);

  const handlePromoCodeChange = (code: string) => {
    setInvalidPromoCode(false);
    setPromoCode(code);
  };

  const handleValidatePromoCode = async () => {
    if (promoCode && selectedOffer) {
      const promoCodeStatus = await validatePromoCode(
        selectedOffer.id === 'pass-offer' ? offerIds[0].offerId : selectedOffer.id,
        promoCode
      );
      if (promoCodeStatus?.status === 'wrong-offer' && promoCodeStatus.offer) {
        setPromoCodeOffer(promoCodeStatus.offer);
      }
      if (promoCodeStatus?.status !== 'valid') {
        setInvalidPromoCode(true);
        return false;
      }
      try {
        const { data } = await axios.post('/api/promo-code', {
          token: auth.token,
          offerId: selectedOffer?.id,
          code: promoCode,
        });
        if (data) {
          setReducedPrice(data.reducedPrice);
        } else {
          setInvalidPromoCode(true);
          return false;
        }
      } catch {
        setReducedPrice(undefined);
      }
      return promoCodeStatus?.status === 'valid';
    }
    return false;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (promoCode && selectedOffer) {
      const validPromoCode = await handleValidatePromoCode();
      if (!validPromoCode) {
        setInvalidPromoCode(true);
        return;
      }
    }

    setPaymentError(false);
    setStep(BuyOfferStep.Payment);
  };

  return (
    <>
      {promoCodeOffer && (
        <InfoWrapper>
          <Typography variant="titleMedium">
            Din kampanjkod gäller för {promoCodeOffer.title}, gå vidare för att aktivera {promoCodeOffer.title}.
          </Typography>
          <InfoWrapperButtons>
            <Button color="red" size="large" onClick={() => setPromoCodeOffer(null)}>
              Avbryt
            </Button>
            <Button
              size="large"
              color="red"
              onClick={() => {
                onSelectOffer(promoCodeOffer);
                setPromoCodeOffer(null);
                setInvalidPromoCode(false);
              }}
            >
              Byt abonnemangstyp
            </Button>
          </InfoWrapperButtons>
        </InfoWrapper>
      )}
      {auth.error && (
        <Typography
          variant="bodySmall"
          styles={css`
            text-align: center;
          `}
        >
          Betalning kan inte påbörjas – eventuell kampanjkod är ogiltig eller förbrukad, skriv in korrekt kampanjkod
          eller mejla kontakt@drakenfilm.se
        </Typography>
      )}
      <PaymentErrorMessage error={paymentError} />
      {step === BuyOfferStep.SelectOffer && !promoCodeOffer && (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Box mb={8} mt={5}>
            <SelectOffer
              includedIds={offerIds}
              preSelectedId={selectedOffer?.id}
              selectedId={selectedOffer?.id}
              onSelectedChange={onSelectOffer}
            />
          </Box>
          <Box mb={5}>
            <PromoCodeField onSetValue={handlePromoCodeChange} promoCode={promoCode} error={invalidPromoCode} />
          </Box>
          <FormControl fullWidth margin="normal">
            <Button size="large" color="red" disabled={!selectedOffer || invalidPromoCode}>
              Gå vidare
            </Button>
          </FormControl>
        </form>
      )}
      {step === BuyOfferStep.Payment && (
        <>
          <Box mb={4}>
            <SelectedOffer offer={selectedOffer} reducedPrice={reducedPrice} />
          </Box>
          <Payment offerId={selectedOffer?.id} promoCode={promoCode} onError={handlePaymentError} />
        </>
      )}
    </>
  );
};
