import { Box, FormControl, Typography } from '@material-ui/core';
import { FC, useRef } from 'react';

import { Button } from '@/components/Button/Button';
import { IPayment } from '@/models/IPaymentHistory.interface';
import { PaymentHistoryPrintTemplate } from '../../../PrintTemplates/PaymentHistoryPrintTemplate';
import { formatAmount } from '@/utils/formatAmount';
import formatDate from '@/utils/formatDate';
import { useAccountState } from '@/context/accountContext';
import { useReactToPrint } from 'react-to-print';

interface PaymentHistoryDialogProps {
  data?: Partial<IPayment> | null;
}

export const PaymentHistoryDialogStep: FC<PaymentHistoryDialogProps> = ({ data }) => {
  const account = useAccountState();

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: false,
    documentTitle: 'Kvitto - DrakenFilm',
  });

  return (
    <>
      <Box mb={8} mt={5}>
        <Typography variant="body2">
          <strong>Dragon Channel AB</strong> <br />
          Olof Palmes plats 1 <br />
          413 04 Göteborg <br />
          556956-6788 <br />
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Användare:</strong> {account.auth.userId}
        </Typography>
        <Typography variant="body2">
          <strong>Epost:</strong> {account.auth.openId}
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Transaktionsdatum:</strong> {formatDate(data?.timestamp ?? '')}
        </Typography>
        <Typography variant="body2">
          <strong>Beskrivning:</strong> {data?.offerV3?.title} {data?.offerV3?.id}
        </Typography>
        <Typography variant="body2">
          <strong>Period:</strong> {formatDate(data?.fromDate ?? '')} - {formatDate(data?.toDate ?? '')}
        </Typography>
        {data?.netPrice !== undefined && (
          <Typography variant="body2">
            <strong>Belopp:</strong> {formatAmount(data?.netPrice)} {data?.currency}
          </Typography>
        )}
        {data?.netPrice !== undefined && (
          <Typography variant="body2">
            <strong>Varav moms (25%):</strong> {formatAmount(data?.netPrice - data?.netPrice / 1.25)} {data?.currency}
          </Typography>
        )}
        {data?.netPrice !== undefined && (
          <>
            <br />
            <Typography variant="body2">
              <strong>Betalat belopp:</strong> {formatAmount(data.netPrice)} {data?.currency}
            </Typography>
          </>
        )}

        <br />
      </Box>

      <PaymentHistoryPrintTemplate ref={componentRef} data={data} account={account} />

      <FormControl fullWidth margin="normal">
        <Button type="submit" color="red" size="large" onClick={handlePrint}>
          Skriv ut
        </Button>
      </FormControl>
    </>
  );
};
