import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.speedDial};
  padding-top: ${({ theme }) => `calc(${theme.spacing(10)}px + ${theme.mixins.toolbar.minHeight}px)`};
  overflow-y: auto;
  scroll-behavior: smooth;
`;
