import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { PaymentType } from '@/models/Payment.enum';

export const getPaymentRedirectUrl = (baseUrl: string, resultCode: AdyenPaymentResultCode): string => {
  const url = new URL(baseUrl);
  url.searchParams.append('authResult', resultCode);
  return url.href;
};

interface PaymentResultMap {
  [key: number]: { [key: string]: string };
}

const paymentResultMessages: PaymentResultMap = {
  [PaymentType.MakePayment]: {
    [AdyenPaymentResultCode.Authorised]:
      'Ditt konto är nu aktiverat och du kan börja streama film direkt i din webbläsare eller via våra appar för iOS, Android, Android TV, Apple TV eller Chromecast.',
    [AdyenPaymentResultCode.Refused]:
      'Betalningen kunde inte genomföras, kontrollera att rätt kortuppgifter angetts och att kortet är giltigt och har täckning.',
    [AdyenPaymentResultCode.Cancelled]: 'Registreringen avbröts. Prova igen senare.',
    [AdyenPaymentResultCode.Pending]: 'Ett registreringsförsök pågår redan. Prova igen senare.',
    [AdyenPaymentResultCode.Error]:
      'Betalningen kunde inte genomföras. Prova igen senare eller mejla kontakt@drakenfilm.se',
  },
  [PaymentType.SavePayment]: {
    [AdyenPaymentResultCode.Authorised]: 'Uppgifter har sparats',
    [AdyenPaymentResultCode.Refused]:
      'Uppdateringen kunde inte genomföras, kontrollera att rätt kortuppgifter angetts och att kortet är giltigt och har täckning.',
    [AdyenPaymentResultCode.Cancelled]: 'Uppdateringen avbröts. Prova igen senare.',
    [AdyenPaymentResultCode.Pending]: 'Ett uppdateringsförsök pågår redan. Prova igen senare.',
    [AdyenPaymentResultCode.Error]:
      'Uppdateringen kunde inte genomföras. Prova igen senare eller mejla kontakt@drakenfilm.se',
  },
};

export const getPaymentResultMessage = (resultCode: AdyenPaymentResultCode, type: PaymentType): string | undefined => {
  return paymentResultMessages[type][resultCode];
};
