import { Article, Category, Media, OffersStylesConfig } from '@/payload-types';

export const isTypename =
  <T extends string>(typename: T) =>
  <N extends { __typename: string }>(node?: N): node is Extract<N, { __typename: T }> => {
    return node?.__typename === typename;
  };

export const isMedia = (source: string | Media): source is Media => (source as Media)?.id !== undefined;

export const isCategory = (source: string | Category): source is Category => (source as Category)?.id !== undefined;

export const isArticle = (source: string | Article): source is Article => (source as Article)?.id !== undefined;

export const isOffer = (source: string | OffersStylesConfig): source is OffersStylesConfig =>
  (source as OffersStylesConfig)?.id !== undefined;

export interface PayloadCollectionResponse<T> {
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: null | number;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
}
