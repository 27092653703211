import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { FC, useState } from 'react';

import { mqMax } from '@/styles/breakpoints';

import { StyledImage, StyledNextLink } from './MovieCard.styles';

import { Typography } from '../Typography';

interface MovieCardProps {
  customId?: string | null;
  title?: string | null;
  thumbnail?: string | null;
  textColor?: string;
  hoverColor?: string;
  onClick: () => void;
  orientation?: 'horizontal' | 'vertical';
  isCollectionLink?: boolean;
  magineId?: string;
}

interface TitleProps {
  show: boolean;
}

const TitleContainer = styled.div<TitleProps>`
  margin-top: 12px;
  word-break: break-word;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

export const MovieCard: FC<MovieCardProps> = ({
  customId,
  title,
  thumbnail,
  textColor = '#FFFFFF',
  hoverColor,
  onClick,
  orientation = 'vertical',
  isCollectionLink = false,
  magineId,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = useMediaQuery(mqMax[3]);

  return (
    <StyledNextLink
      onClick={onClick}
      href={isCollectionLink ? `/kollektion/${magineId}` : `/film/${customId}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        {thumbnail && (
          <StyledImage
            alt={title ?? ''}
            src={thumbnail}
            width={orientation === 'horizontal' ? 455 : 256}
            height={orientation === 'horizontal' ? 256 : 366}
            hoverColor={hoverColor}
            isHorizontal={orientation === 'horizontal'}
          />
        )}
        <TitleContainer color={textColor} show={(isCollectionLink && isMobile) || isHovered}>
          <Typography variant="bodySmall">{title}</Typography>
        </TitleContainer>
      </div>
    </StyledNextLink>
  );
};
