import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import NextLink from 'next/link';

export const StyledNextLink = styled(NextLink)`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledImage = styled.img<{ hoverColor?: string; isHorizontal?: boolean }>`
  width: 100%;
  aspect-ratio: ${({ isHorizontal }) => (isHorizontal ? '455/256' : '256/366')};
  height: auto;
  outline-offset: -2px;
  :hover {
    outline: 2px solid ${(props) => (props.hoverColor ? props.hoverColor : 'none')};
  }
`;

export const Title = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(2)};
  word-break: break-word;
`;
