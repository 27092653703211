import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

// typePolicies is not required to use Apollo with Next.js - only for doing pagination.
const cache = new InMemoryCache({
  typePolicies: {
    PosterCollection: relayStylePagination(),
    BookmarksCollection: relayStylePagination(),
    ContinueWatchingCollection: relayStylePagination(),
    EntitledContentCollection: relayStylePagination(),
    FavouriteChannelsCollection: relayStylePagination(),
    FeaturedCarouselCollection: relayStylePagination(),
    FeaturedCollection: relayStylePagination(),
    LiveChannelsCollection: relayStylePagination(),
    SixteenNineCollection: relayStylePagination(),
    LinkCollection: relayStylePagination(),
  },
});

export default cache;
