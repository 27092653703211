// https://docs.adyen.com/online-payments/payment-result-codes
export enum AdyenPaymentResultCode {
  AuthenticationFinished = 'AuthenticationFinished',
  AuthenticationNotRequired = 'AuthenticationNotRequired',
  Authorised = 'Authorised',
  Cancelled = 'Cancelled',
  ChallengeShopper = 'ChallengeShopper',
  Error = 'Error',
  IdentifyShopper = 'IdentifyShopper',
  Pending = 'Pending',
  PresentToShopper = 'PresentToShopper',
  Received = 'Received',
  RedirectShopper = 'RedirectShopper',
  Refused = 'Refused',
}

const resultCodes = [
  AdyenPaymentResultCode.AuthenticationFinished,
  AdyenPaymentResultCode.AuthenticationNotRequired,
  AdyenPaymentResultCode.Authorised,
  AdyenPaymentResultCode.Cancelled,
  AdyenPaymentResultCode.ChallengeShopper,
  AdyenPaymentResultCode.Error,
  AdyenPaymentResultCode.IdentifyShopper,
  AdyenPaymentResultCode.Pending,
  AdyenPaymentResultCode.PresentToShopper,
  AdyenPaymentResultCode.Received,
  AdyenPaymentResultCode.RedirectShopper,
  AdyenPaymentResultCode.Refused,
];

export const toAdyenPaymentResultCode = (code: string): AdyenPaymentResultCode | undefined => {
  return resultCodes.filter((c) => c.toLowerCase() === code.toLowerCase())[0];
};
