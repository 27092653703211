import { initializeApollo } from '@/graphql/client';
import { getFilteredSearchMovies } from '@/graphql/hooks/movies';
import { GetSearchDocument } from '@/graphql/queries/__generated__/movie.generated';

export const DEFAULT_LIMIT = 42;
export const DEFAULT_QUERY = 'drama';

const apolloClient = initializeApollo();

export const getSearchResult: (
  query?: string,
  after?: string,
  token?: string | null,
  clientIp?: string | string[]
) => Promise<[ReturnType<typeof getFilteredSearchMovies>, boolean, string]> = async (query, after, token, clientIp) => {
  const { data } = await apolloClient.query({
    query: GetSearchDocument,
    context: {
      authToken: token,
      clientIp,
      fetchOptions: {
        next: { revalidate: 5 },
      },
    },
    variables: {
      query: query ?? DEFAULT_QUERY,
      first: DEFAULT_LIMIT,
      ...(after ? { after } : {}),
    },
  });

  const { search } = data.viewer;
  const movies = getFilteredSearchMovies(data?.viewer.search.edges);

  const { pageInfo } = search;

  const { hasNextPage, endCursor } = pageInfo;

  return [movies, hasNextPage, endCursor];
};

export const getPaginatedSearchResults = async (
  query?: string,
  cursors?: Array<string | undefined>,
  authToken?: string | null,
  clientIp?: string | string[]
) => {
  const responses = await Promise.all(
    (cursors ?? [undefined]).map(async (a) => await getSearchResult(query, a, authToken, clientIp))
  );
  let movies: ReturnType<typeof getFilteredSearchMovies> = [];
  let lastHasNextPage = false;
  let lastEndCursor: string | null = '';
  responses.forEach(([_movies, _hasNextPage, _endCursor]) => {
    movies = [...movies, ...(_movies ?? [])];
    lastHasNextPage = _hasNextPage;
    lastEndCursor = _endCursor;
  });

  return {
    movies,
    lastHasNextPage,
    lastEndCursor,
  };
};
