import styled from '@emotion/styled';

import { media } from '@/styles';
import { Container } from '@material-ui/core';

export const SearchContainer = styled(Container)`
  position: relative;
  z-index: 9;
  padding: 1rem;

  ${media.sm} {
    padding: 2rem;
  }
`;

export const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 4rem;

  ${media.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${media.smm} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${media.md} {
    // margin: 2rem 4rem;
  }

  ${media.mdl} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const MovieCardWrapper = styled.div`
  flex: 0 0 50%;
  padding: ${({ theme }) => theme.spacing(0, 2, 4)};

  ${media.sm} {
    flex: 0 0 calc(100% / 2);
  }

  ${media.md} {
    flex: 0 0 calc(100% / 4);
  }

  ${media.lg} {
    flex: 0 0 calc(100% / 4);
  }
`;
