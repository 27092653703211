import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import DrawerMenu from '@/components/NavBar/DrawerMenu';
import { SearchInput } from '@/components/SearchInput';
import LogoIcon from '@/components/icons/LogoIcon';
import { useNavigation } from '@/context/NavigationContext';
import { useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { useSearchUrl } from '@/hooks/useSearchUrl';
import { mqMax } from '@/styles/breakpoints';

import MenuItems from './MenuItems';
import { AppBar, DesktopVisible, Logo, MenuButton, Nav, Toolbar, LogoText, DragonContainer } from './NavBar.styles';

import { Button } from '../Button/Button';
import LinkButton from '../Button/LinkButton';
import BasicContainer from '../Containers/BasicContainer';
import DragonLogoWhite from '../icons/new/DragonLogoWhite';
import { useRouter } from 'next/router';

const JoinButton = styled.div`
  margin-left: 1rem;
`;

export const NavBar: FC = () => {
  const { urlQuery, updateUrl } = useSearchUrl();

  const [open, setOpen] = useState(false);

  const [searchOpen, setSearchOpen] = useState(!!urlQuery);
  const [searchValue, setSearchValue] = useState(urlQuery);

  const [shrink, setShrink] = useState(false);

  const { mainNav, footerNav } = useNavigation();

  const isMobile = useMediaQuery(mqMax[6]);
  const lt1024 = useMediaQuery(mqMax[3]);

  const { pathname } = useRouter();
  const isStartPage = pathname === '/';

  const searchRef = useRef<HTMLDivElement>(null);
  const debouncedSearch = useRef(
    debounce((query: string) => {
      updateUrl(query);
    }, 300)
  );

  const { auth } = useAccountState();
  const { isActiveSubscriber } = useSubscriptionData();

  const dialogDispatch = useDialogDispatch();

  const toggleSearchOpen = () => {
    setSearchOpen((prevState) => !prevState);
  };

  const handleScroll = useCallback(() => {
    if (document.documentElement.scrollTop >= 10 ?? !!urlQuery) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  }, [urlQuery]);

  const handleWindowClick = useCallback(
    (event: MouseEvent) => {
      if (event.target && !searchRef.current?.contains(event.target as Node) && !urlQuery) {
        setSearchOpen(false);
      }
    },
    [urlQuery]
  );

  const toggleMenu = (value: boolean) => {
    setOpen(value);
  };

  const handleSearchChange = (query = '') => {
    debouncedSearch.current(query);
    setSearchValue(query);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleWindowClick);
    };
  }, [handleScroll, handleWindowClick]);

  const handleLogIn = () => {
    dialogDispatch({ type: dialogActionTypes.DIALOG_AUTH, payload: { skipOffers: true } });
  };

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);  

  return (
    <AppBar position="fixed" color="transparent" elevation={0} shrink={shrink} showfade={!isStartPage}>
      <BasicContainer>
        <Toolbar isExpanded={searchOpen}>
          {!searchOpen && (
            <Box display="flex" alignItems="center">
              <Logo
                title="Logo"
                href={auth?.token ? '/filmer' : '/'}
                style={{
                  visibility: (shrink || !isStartPage) ? 'visible' : 'hidden',
                }}
              >
                <LogoIcon />
              </Logo>
              <DesktopVisible>
                {auth.token ? (
                  ''
                ) : (
                  <Box
                    fontSize="24px"
                    fontWeight="500"
                    ml="1rem"
                    letterSpacing="-0.6px"
                    style={{
                      opacity: shrink ? '1' : '0'
                    }}
                  >
                    Draken Film
                  </Box>
                )}
              </DesktopVisible>
            </Box>
          )}

          <Box display="flex" flexGrow={searchOpen ? 1 : 0} alignItems="center">
            {!searchOpen && (
              <Nav>
                {(!isActiveSubscriber || !auth?.token) && !lt1024 && (
                  <Box display="flex" ml={!auth.token ? 6 : 3} mr={!auth.token ? 6 : 3}>
                    <Button href="/offers" size="small" color="white" openInNewTab={false}>
                      {!auth?.token ? 'Gå med' : 'Börja titta'}
                    </Button>
                  </Box>
                )}
                <DesktopVisible>
                  {!auth.token && <MenuItems pages={mainNav} token={auth?.token} onClose={() => toggleMenu(false)} />}
                </DesktopVisible>
                <DesktopVisible>
                  <>
                    {!auth.token && (
                      <Box ml={{md: 0, mdl: 3}} mr={6}>
                        <LinkButton
                          onClick={handleLogIn}
                          color="#3B7AA6"
                          arrowColor="#FFFFFF"
                          arrowPlacement="right"
                          arrowStyles={css`
                            rotate: -45deg;
                          `}
                          fontWeight="400"
                        >
                          Logga in
                        </LinkButton>
                      </Box>
                    )}
                  </>
                </DesktopVisible>
              </Nav>
            )}
            <Box
              display="flex"
              justifyContent={searchOpen ? 'center' : 'flex-end'}
              alignItems="center"
              flexGrow={searchOpen ? 1 : 0}
            >
              <SearchInput
                expanded={searchOpen}
                onButtonClick={toggleSearchOpen}
                onSearch={handleSearchChange}
                query={searchValue}
                ref={searchRef}
                onMobileClose={() => setSearchOpen(false)}
              />
            </Box>
            {!searchOpen && (auth?.token || isMobile) && (
              <MenuButton
                data-testid="menu-icon"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleMenu(true)}
              >
                <MenuIcon />
              </MenuButton>
            )}

            {!searchOpen && (
              <DragonContainer>
                <DragonLogoWhite />
              </DragonContainer>
            )}
          </Box>
        </Toolbar>
      </BasicContainer>
      <DrawerMenu pages={mainNav} footerNav={footerNav} toggle={toggleMenu} open={open} />
    </AppBar>
  );
};
