import { Box, Container, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FC, useState } from 'react';

import { buttonClick } from '@/utils/analytics';

import { Button } from './Button/Button';

import getMeta from '../utils/getMeta';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#54211A",
    padding: theme.spacing(7, 11),
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: "2rem",

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  button: {
    whiteSpace: 'nowrap',
    margin: theme.spacing(5, 0, 0, 0),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 0, 5),
    },
  },
}));

const CookieBanner: FC = () => {
  const classes = useStyles();

  const [show, setShow] = useState(true);

  const handleOnClick = async () => {
    const { data } = await axios.post<boolean>(`/api/auth/cookie`, { name: 'df_cc', value: true });
    if (data) {
      setShow(false);
    }

    buttonClick('cookieConsent', getMeta('pageType'));
  };

  return show ? (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Typography variant="body2">
            Draken Film använder cookies för att webbplatsen ska fungera på ett bra sätt.
            <br /> Genom att surfa vidare godkänner du att vi använder cookies
          </Typography>
          <Button color="red" onClick={handleOnClick}>
            Jag förstår!
          </Button>
        </Box>
      </Container>
    </Box>
  ) : null;
};

export default CookieBanner;
