import { useRouter } from 'next/router';
import { FC, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { Search } from '@/components/Search';
import { useSearch } from '@/hooks/useSearch';
import { useSearchUrl } from '@/hooks/useSearchUrl';

import { Wrapper } from './GlobalSearchOverlay.styles';

interface GlobalSearchOverlayProps {
  clientIp: string;
  authToken?: string;
}
export const GlobalSearchOverlay: FC<GlobalSearchOverlayProps> = ({ clientIp, authToken }) => {
  const { urlQuery } = useSearchUrl();
  const nodeRef = useRef<HTMLDivElement>(null);
  const { pathname } = useRouter();

  const { results, fetchSearchResults, isFetching, endCursor, hasNextPage } = useSearch({
    query: urlQuery,
    clientIp,
    authToken,
    fetchOnChange: true,
  });

  const isOpen = !!urlQuery && pathname !== '/search';

  useEffect(() => {
    if (isOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isOpen]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isOpen}
      addEndListener={(done) => {
        nodeRef.current?.addEventListener('transitionend', done, false);
      }}
      classNames="fade"
      unmountOnExit
    >
      <Wrapper ref={nodeRef} data-scroll-lock-scrollable>
        <Search
          fetchSearchResults={fetchSearchResults}
          isFetching={isFetching}
          results={results}
          hasNextPage={hasNextPage}
          endCursor={endCursor}
        />
      </Wrapper>
    </CSSTransition>
  );
};
