import { Box, TextField } from '@material-ui/core';
import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { Typography } from '@/components/Typography';
import validateEmail from '@/utils/validateEmail';

import { AuthForm } from '../../AuthorizationDialog.types';
import styled from '@emotion/styled';
import { mqMax } from '@/styles/breakpoints';
import { css } from '@emotion/react';

interface EmailStepProps {
  errors: FieldErrors<AuthForm>;
  email: string;
  control: Control<AuthForm>;
}

const FieldWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;

const EmailStep: FC<EmailStepProps> = ({ control, email, errors }) => {
  return (
    <div data-testid="login-dialog">
      <Box>
        <Box mb={4}>
          <Typography variant="titleMedium" fontWeight='500' styles={css`
            font-size: 28px;

            ${mqMax[3]} {
              font-size: 28px;
            }
            ${mqMax[4]} {
              font-size: 28px;
            }
          `}>Logga in eller skapa ett konto </Typography>
        </Box>
        <Box mb={8}>
          <Typography variant="bodySmall">Ta del av vårt innehåll. Kom igång genom att ange din e-post:</Typography>
        </Box>
      </Box>
      <Controller
        name="email"
        control={control}
        defaultValue={email}
        rules={{
          required: 'Obligatoriskt fält',
          validate: {
            notValid: (value) => validateEmail(value) || 'Kontrollera din e-post',
          },
        }}
        render={({ field }) => (
          <FieldWrapper>
            <TextField
              {...field}
              fullWidth
              margin="dense"
              variant="outlined"
              id="identity"
              name="identity"
              placeholder="Din e-postadress"
              autoComplete="email"
              type="email"
              error={!!errors.email}
              helperText={<span data-testid="login-error-message">{errors.email?.message}</span>}
              data-testid="user-name-input"
            />
          </FieldWrapper>
        )}
      />
    </div>
  );
};

export default EmailStep;
