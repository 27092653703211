import axios from 'axios';
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IPaymentHistory } from '@/models/IPaymentHistory.interface';
import { IPaymentMethodsResponse, ParsedPaymentMethods } from '@/models/IPaymentMethods.interface';
import { ISubscription } from '@/models/ISubscription.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import { PaymentType } from '@/models/Payment.enum';
import { Setting } from '@/payload-types';
import { activateSubscription } from '@/utils/analytics';

import { useAccountState } from '../accountContext';
import { dialogActionTypes, useDialogDispatch } from '../authorizationDialogContext';
import { useConfig } from '../config/config.hooks';

interface SubscriptionContextProps {
  subscriptionData: ISubscription | null;
  isSubscriber: boolean;
  isActiveSubscriber: boolean;
  paymentHistory?: IPaymentHistory;
  parsedPaymentMethods?: ParsedPaymentMethods;
  refreshSubscriptions: () => void;
  startSubscription: () => void;
  updateSubscription: () => void;
  buySpecialOffer: (specialOffer: Setting['specialOffer']) => void;
  reactivate: (offerId: string) => void;
  getSubscriptions: () => void;
}

const SubscriptionContext = createContext<SubscriptionContextProps | undefined>(undefined);

export const SubscriptionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [subscriptionData, setSubscriptionData] = useState<ISubscription | null>(null);

  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistory>();
  const [parsedPaymentMethods, setParsedPaymentMethods] = useState<ParsedPaymentMethods>();
  const dialogDispatch = useDialogDispatch();
  const account = useAccountState();
  const { offers } = useConfig();

  const getSubscriptions = useCallback(() => {
    if (account.auth?.token) {
      axios
        .post<ISubscription>(`/api/auth/subscription/${account.auth?.token}`)
        .then((response) => {
          setSubscriptionData(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    } else {
      setSubscriptionData(null);
    }
  }, [account?.auth?.token]);

  const getPaymentMethod = useCallback(() => {
    if (account?.auth?.token) {
      axios
        .get<IPaymentMethodsResponse>(`/api/adyen/paymentmethod/${account?.auth?.token}`)
        .then((response) => {
          const paymentMethodsAsString = response.data.data?.viewer.adyenDropInPaymentMethods.paymentMethodsResponse;
          if (paymentMethodsAsString) {
            setParsedPaymentMethods(JSON.parse(paymentMethodsAsString) as ParsedPaymentMethods);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [account?.auth?.token]);

  const fetchPaymentHistory = useCallback(() => {
    if (account?.auth?.token) {
      axios
        .get<IPaymentHistory>(`/api/auth/paymenthistory/${account?.auth?.token}`)
        .then((response) => {
          setPaymentHistory(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [account?.auth?.token]);

  const startSubscription = () => {
    activateSubscription();
    dialogDispatch({
      type: dialogActionTypes.DIALOG_BUY_OFFER,
      payload: {
        selectedId: null,
        typename: OfferType.SubscribeType,
      },
    });
  };

  const updateSubscription = async () => {
    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: { type: PaymentType.SavePayment },
    });
  };

  const reactivate = async (offerId: string) => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/api/offers/uncancel/${offerId}/${account.auth.token}`, {
        userId: account.auth.userId,
      });
    } catch {
      // eslint-disable-next-line no-console
      console.log('Error occured while uncancelling subscription');
    } finally {
      refreshSubscriptions();
    }
  };

  useEffect(() => {
    if (account?.auth?.token) {
      getSubscriptions();
      fetchPaymentHistory();
      getPaymentMethod();
    }
  }, [account?.auth?.token, getSubscriptions, fetchPaymentHistory, getPaymentMethod]);

  const isSubscriber = useMemo(
    () => (subscriptionData ? subscriptionData.data?.viewer?.entitlements?.edges.length > 0 : false),
    [subscriptionData]
  );

  const isActiveSubscriber = useMemo(
    () =>
      subscriptionData
        ? subscriptionData.data?.viewer?.entitlements?.edges.filter(
            (sub) => sub.node.status === 'active' || sub.node.status === 'cancelled'
          ).length > 0
        : false,
    [subscriptionData]
  );
  const refreshSubscriptions = () => {
    getSubscriptions();
  };

  const buySpecialOffer = async (specialOffer: Setting['specialOffer']) => {
    const findOffer = offers?.find((offer) => offer.id === specialOffer?.offerId);

    dialogDispatch({
      type: dialogActionTypes.DIALOG_BUY_OFFER,
      payload: {
        selectedId: specialOffer?.offerId,
        offerIds: [{ offerId: specialOffer?.offerId ?? '' }],
        typename: findOffer && findOffer.__typename === 'PassType' ? OfferType.PassType : OfferType.SubscribeType,
      },
    });
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionData,
        isSubscriber,
        isActiveSubscriber,
        paymentHistory,
        parsedPaymentMethods,
        refreshSubscriptions,
        startSubscription,
        updateSubscription,
        buySpecialOffer,
        reactivate,
        getSubscriptions,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionData = (): SubscriptionContextProps => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscriptionData must be used within a SubscriptionProvider');
  }
  return context;
};
