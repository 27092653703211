import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';

import { variantsStyles } from './variantsStyles';

type BodyVariants = 'bodyExtraSmall' | 'bodySmall' | 'bodyMedium' | 'bodyLarge' | 'bodyExtraLarge';

type DisplayVariants = 'displaySmall' | 'displayMedium' | 'displayLarge' | 'displayLargeDynamic';

type HeadlineVariants = 'headlineXSmall' | 'headlineSmall' | 'headlineMedium' | 'headlineSmallMedium' | 'headlineLarge' | 'headlineLargeDynamic';

type LabelVariants = 'labelFooter' | 'labelSmall' | 'labelMedium' | 'labelLarge';

type TitleVariants = 'titleSmall' | 'titleMedium' | 'titleLarge';

type TypographyVariant = BodyVariants | DisplayVariants | HeadlineVariants | LabelVariants | TitleVariants;

type TypographyProps = {
   customColor?: string;
  variant: TypographyVariant;
  fontStyle?: 'italic' | 'normal';
  fontWeight?: string;
  align?: 'center' | 'left' | 'right';
  children?: ReactNode;
  styles?: SerializedStyles;
  className?: string;
};

const StyledTypography = styled.div<TypographyProps>`
  ${(props) => variantsStyles[props.variant]}
  font-family: 'SunsetGothicPro';
  font-style: ${(props) => props.fontStyle ?? 'normal'};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
  text-align: ${(props) => props.align ?? 'left'};
  color: ${(props) => props.customColor ?? 'white'};
  word-wrap: break-word;
  ${(props) => props.styles}
`;

const Typography: FC<TypographyProps> = ({
  customColor,
  variant,
  fontStyle,
  fontWeight,
  children,
  align,
  styles,
  className,
}) => {
  return (
    <StyledTypography
      variant={variant}
      customColor={customColor}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      align={align}
      styles={styles}
      className={className}
    >
      {children}
    </StyledTypography>
  );
};

export { Typography };
