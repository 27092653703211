import { Box } from '@material-ui/core';
import { FC } from 'react';

import { Page } from '@/utils/pages';

import LinkButton from '../Button/LinkButton';

interface MenuItemProps {
  pages: Page[];
  token?: string | null;
  onClose: () => void;
  isMobile?: boolean;
}

const MenuItems: FC<MenuItemProps> = ({ pages, token, onClose, isMobile }) => {
  const getMenuItemColor = (index: string) => {
    if(isMobile) return undefined;
    switch (index) {
      case 'filmer':
        return '#EC6446';
      case 'redaktionellt':
        return '#569979';
      default:
        return undefined;
    }
  };
  return (
    <>
      {pages.map((page, index) => {
        if(page.displayInHeader || isMobile){

          if (page.isPrivate) {
            if (token) {
              return (
                <Box mt="1rem" mb="1rem" key={page.slug}>
                  <LinkButton
                    path={page?.href ? page.href : `/${page.slug}`}
                    onClick={onClose}
                    fontStyle="normal"
                    fontWeight='400'
                    className="menu-item"
                  >
                    {page.label}
                  </LinkButton>
                </Box>
              );
            } else return null;
          } else {
            return token ? (
              <Box mt="1rem" mb="1rem" key={page.slug}>
                <LinkButton
                  path={page?.href ? page.href : `/${page.slug}`}
                  onClick={onClose}
                  fontStyle="normal"
                  fontWeight='400'
                  className="menu-item"
                >
                  {page.label}
                </LinkButton>
              </Box>
            ) : (
              <Box ml={{md: 0, mdl: 3}} mr={3} mt="1rem" mb="1rem" key={page.slug}>
                <LinkButton
                  path={page?.href ? page.href : `/${page.slug}`}
                  key={page.slug}
                  onClick={onClose}
                  fontStyle={isMobile ? "normal" : "italic"}
                  arrowPlacement={isMobile ? undefined : "right"}
                  color={getMenuItemColor(page.slug || '')}
                  arrowColor="#FFFFFF"
                  className="menu-item"
                  fontWeight='400'
                >
                  {page.label}
                </LinkButton>
              </Box>
            );
          }
          
        }
      })}
    </>
  );
};

export default MenuItems;
