import styled from '@emotion/styled';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { ButtonHTMLAttributes, ReactNode } from 'react';

export const getFontColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return '#000000';
    case 'white':
      return '#ffffff';
    case 'red':
      return '#ffffff';
    default:
      return '#000000';
  }
};

export const getBackgroundColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return 'transparent';
    case 'white':
      return 'transparent';
    case 'red':
      return '#EC6446';
    default:
      return 'transparent';
  }
};

export const getHoverColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return '#000000';
    case 'white':
      return '#ffffff';
    case 'red':
      return '#54211A';
    default:
      return '#000000';
  }
};

export const getHoverFontColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return '#ffffff';
    case 'white':
      return '#000000';
    case 'red':
      return '#ffffff';
    default:
      return '#ffffff';
  }
};

export const getActiveColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return '#000000';
    case 'white':
      return 'rgba(255, 255, 255, 0.7)';
    case 'red':
      return '#311001';
    default:
      return '#000000';
  }
};

export const getBorderColor = (color?: FontColor) => {
  switch (color) {
    case 'black':
      return '#000000';
    case 'white':
      return '#ffffff';
    case 'red':
      return '#ffffff';
    default:
      return '#000000';
  }
};

export const StyledButton = styled.button<StyledButtonProps>`
  font-family: 'SunsetGothicPro';
  display: flex;
  align-items: center;
  color: ${({ color, disabled }) => disabled ? "#0f0f0f" : getFontColor(color)};
  background-color: ${({ color, disabled }) => disabled ? "#424242" : getBackgroundColor(color)};
  border: 2px solid ${({ color, disabled }) => disabled ? "#424242" : getBorderColor(color)};
  border-radius: 50px;
  padding: ${({ size }) => (size === 'small' ? '10px 18px;' : '16px 22px')};
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  img {
    filter: ${({ color }) => hexToCSSFilter(getFontColor(color)).filter};
  }

  &:active {
    background-color: ${({ color }) => getActiveColor(color)};
  }
  &:hover {
    color: ${({ color, disabled }) => disabled ? "#0f0f0f" : getHoverFontColor(color)};
    background-color: ${({ color, disabled }) => disabled ? "#424242" : getHoverColor(color)};
    img {
      filter: ${({ color }) => hexToCSSFilter(getHoverFontColor(color)).filter};
    }
    mix-blend-mode: ${({ multiply }) => (multiply ? 'multiply' : 'normal')};
  }
`;

export const StartIconWrapper = styled.div<StyledButtonProps>`
  height: 1.2rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const IconButtonWrapper = styled.div<StyledButtonProps>`
  color: ${({ color }) => getFontColor(color)};
  background-color: ${({ color }) => getBackgroundColor(color)};
  border: 2px solid ${({ color }) => getBorderColor(color)};
  border-radius: 50px;

  padding: ${({ size }) => (size === 'small' ? '7px' : '16px')};
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-sizing: content-box;
  height: 23.5px;
  width: 23.5px;

  img {
    filter: ${({ color }) => hexToCSSFilter(getFontColor(color)).filter};
  }
  &:hover {
    color: ${({ color, hoverFontColor }) => hoverFontColor ?? getHoverFontColor(color)};
    background-color: ${({ color, hoverBackgroundColor }) => hoverBackgroundColor ?? getHoverColor(color)};
    img {
      filter: ${({ color, hoverFontColor }) => hexToCSSFilter(hoverFontColor ?? getHoverFontColor(color)).filter};
    }
  }
`;

export type FontColor = 'black' | 'white' | 'red';

export interface StyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'large';
  color?: FontColor;
  isHovering?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  hoverBackgroundColor?: string;
  hoverFontColor?: string;
  multiply?: boolean;
}
export interface ButtonProps extends StyledButtonProps {
  children?: ReactNode;
  href?: string;
  onClick?: () => void;
  dataTestId?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
  openInNewTab?: boolean;
  multiply?: boolean;
}
