import axios from 'axios';
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { initializeApollo } from '@/graphql/client';
import { GetBasicCollectionDocument } from '@/graphql/queries/__generated__/collection.generated';
import { ViewableEdge } from '@/models/ICollection.interface';

import { useAccountState } from '../accountContext';

interface BookmarksContextProps {
  addToFavorites: (magineId: string) => void;
  removeFromFavorites: (magineId: string) => void;
  getBookmarksCollection: () => void;
  bookmarksCollection: ViewableEdge[];
}

const BookmarksContext = createContext<BookmarksContextProps | undefined>(undefined);

export const BookmarksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const bookmarksCollectionId = 'd21f2339-62d1-45e9-b175-eef4e9987c6d';
  const [bookmarksCollection, setBookmarksCollection] = useState<ViewableEdge[]>([]);

  const apolloClient = initializeApollo();

  const { auth } = useAccountState();

  const addToFavorites = async (magineId: string) => {
    await axios
      .put(`/api/movies/favourites/${auth?.token}/${magineId}`)
      .then(() => {
        getBookmarksCollection();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const removeFromFavorites = async (magineId: string) => {
    await axios
      .delete(`/api/movies/favourites/${auth?.token}/${magineId}`)
      .then(() => {
        getBookmarksCollection();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const getBookmarksCollection = useCallback(async () => {
    if (auth?.token) {
      await apolloClient
        .query({
          query: GetBasicCollectionDocument,
          context: { authToken: auth?.token },
          fetchPolicy: 'no-cache',
          variables: {
            id: bookmarksCollectionId,
            first: 10,
          },
        })
        .then((response) => {
          setBookmarksCollection(response?.data?.viewer?.block?.viewables?.edges ?? []);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [apolloClient, auth?.token]);

  useEffect(() => {
    if (auth?.token) {
      getBookmarksCollection();
    }
  }, [auth?.token, getBookmarksCollection]);

  return (
    <BookmarksContext.Provider
      value={{
        addToFavorites,
        removeFromFavorites,
        getBookmarksCollection,
        bookmarksCollection,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
};

export const useBookmarks = (): BookmarksContextProps => {
  const context = useContext(BookmarksContext);
  if (!context) {
    throw new Error('useBookmarks must be used within a BookmarksContextProvider');
  }
  return context;
};
