import { Box, FormControl, TextField } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FC, FormEvent, useEffect, useState } from 'react';

import { Button } from '@/components/Button/Button';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { userForgottPassword } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';
import validateEmail from '@/utils/validateEmail';
import { css } from '@emotion/react';
import { mqMax } from '@/styles/breakpoints';
import { Typography } from '@/components/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Radikal-medium',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

interface ForgotPasswordDialogStepProps {
  email?: string;
}

export const ForgotPasswordDialogStep: FC<ForgotPasswordDialogStepProps> = ({ email = '' }) => {
  const classes = useStyles();
  const dialogDispatch = useDialogDispatch();

  const [_email, setEmail] = useState(email);
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await axios.post(`/api/auth/forgot-password`, {
      email: _email,
      locale: 'se',
    });

    userForgottPassword(getMeta('pageType'));
    dialogDispatch({ type: dialogActionTypes.CLOSE_ALL });
  };

  useEffect(() => {
    if (!!_email && validateEmail(_email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [_email]);

  return (
    <>
      <Box mb={5} display="flex" flexDirection="column">
      <Typography variant="titleMedium" fontWeight='500' styles={css`
            font-size: 28px;
            padding-bottom: 1rem;
            ${mqMax[3]} {
              font-size: 28px;
            }
            ${mqMax[4]} {
              font-size: 28px;
            }
          `}>Ändra ditt lösenord</Typography>

        <Typography variant="bodySmall">
          Ange din e-postadress nedan så skickar vi instruktioner för hur du återställer ditt lösenord
        </Typography>
      </Box>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            required
            margin="dense"
            variant="outlined"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Din e-postadress"
            value={_email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <FormControl fullWidth margin="normal">
          <Button type="submit" size="large" color="red" disabled={!isValid}>
            Skicka verifieringskod
          </Button>
        </FormControl>
      </form>
    </>
  );
};
