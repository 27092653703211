import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { IOffer } from '@/models/IOffer.interface';
import { OffersStylesConfig } from '@/payload-types';
import { PayloadCollectionResponse } from '@/utils/typescript';

export const useOffers = () => {
  const [offers, setOffers] = useState<IOffer | null>(null);
  const [offersStylesConfig, setOffersStylesConfig] = useState<OffersStylesConfig[] | null>(null);

  const getOffersStylesConfig = useCallback(() => {
    axios
      .get<PayloadCollectionResponse<OffersStylesConfig>>(
        `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/offers-styles-config`
      )
      .then((response) => {
        setOffersStylesConfig(response.data.docs);
      });
  }, []);

  const getOffers = useCallback(() => {
    axios.get<IOffer>(`/api/offers/null`).then((response) => {
      setOffers(response.data);
    });
  }, []);

  useEffect(() => {
    getOffers();
    getOffersStylesConfig();
  }, [getOffers, getOffersStylesConfig]);

  return { offers, offersStylesConfig };
};
