import { Box, FormControl, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FC, FormEvent, useEffect, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { accountActionTypes, useAccountDispatch } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { Button } from '@/components/Button/Button';
import EyeAdornment from '@/components/EyeAdornment/EyeAdornment';
import axios from 'axios';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

export const ResetPasswordDialogStep: FC = () => {
  const classes = useStyles();
  const dialogDispatch = useDialogDispatch();
  const { dispatch: accountDispatch } = useAccountDispatch();
  const router = useRouter();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });
  const password = watch('password', '');

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (formData: any) => {
    const { data } = await axios.post(`/api/auth/reset-password`, {
      token: router.query.reset_password_token,
      password: formData.password,
    });

    if (data?.error) {
      setError(true);
    } else {
      setSuccess(true);
      // dialogDispatch({ type: dialogActionTypes.CLOSE_ALL });
      // router.push('/');
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    accountDispatch({ type: accountActionTypes.LOGOUT });
  }, []);

  return (
    <>
      <Box mb={5} display="flex" flexDirection="column" alignItems="center">
        {success ? (
          <Typography variant="h2" className={classes.title}>
            Ditt lösenord har ändrats.
          </Typography>
        ) : (
          <Typography variant="h2" className={classes.title}>
            Ändra ditt lösenord
          </Typography>
        )}
      </Box>
      {success ? (
        <Typography variant="body2">Nu kan du logga in med din mejladress och ditt nya lösenord.</Typography>
      ) : error ? (
        <Typography variant="body2" color="error">
          Länken för att återställa ditt lösenord är förbrukad, begär en ny länk via "Glömt lösenord" på
          inloggningssidan – tänk på att länken bara fungerar för ett återställningsförsök
        </Typography>
      ) : (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  required
                  onChange={onChange}
                  value={value}
                  margin="dense"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Lösenord"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <EyeAdornment
                        handleClickShowPassword={toggleShowPassword}
                        handleMouseDownPassword={toggleShowPassword}
                        showPassword={showPassword}
                      />
                    ),
                  }}
                />
              )}
              name="password"
              control={control}
              rules={{
                required: 'Obligatoriskt fält',
                minLength: {
                  value: 6,
                  message: 'Lösenordet måste vara minst 6 tecken långt',
                },
              }}
              defaultValue=""
            />
            {errors.password && <p>{errors.password?.message as string}</p>}
          </Box>
          <Box mb={3}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  required
                  onChange={onChange}
                  value={value}
                  margin="dense"
                  id="passwordRepeat"
                  type={showPassword ? 'text' : 'password'}
                  name="passwordRepeat"
                  placeholder="Upprepa lösenord"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <EyeAdornment
                        handleClickShowPassword={toggleShowPassword}
                        handleMouseDownPassword={toggleShowPassword}
                        showPassword={showPassword}
                      />
                    ),
                  }}
                />
              )}
              name="passwordRepeat"
              control={control}
              rules={{
                required: 'Obligatoriskt fält',
                validate: (value) => value === password || 'Lösenorden matchar inte',
              }}
              defaultValue=""
            />
            {errors.passwordRepeat && <p>{errors.passwordRepeat?.message as string}</p>}
          </Box>
          <FormControl fullWidth margin="normal">
            <Button type="submit" size="large" color="red" disabled={!isValid}>
              Ändra lösenord
            </Button>
          </FormControl>
        </form>
      )}
    </>
  );
};
