import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Divider } from '@material-ui/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import axios from 'axios';

import LinkButton from '@/components/Button/LinkButton';
import { Typography } from '@/components/Typography';
import { accountActionTypes, useAccountDispatch } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { Page } from '@/utils/pages';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { Button } from '@/components/Button/Button';

import MenuItems from '../MenuItems';

interface MainMenuProps {
  onClose: () => void;
  pages: Page[];
  auth: IAuthUser;
  moveToCategories: () => void;
  footerNav: Page[];
}

const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainMenuWraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MainMenu: FC<MainMenuProps> = ({ onClose, pages, auth, moveToCategories, footerNav }) => {
  const { dispatch: accountDispatch } = useAccountDispatch();
  const dialogDispatch = useDialogDispatch();
  const router = useRouter();
  const { isActiveSubscriber } = useSubscriptionData();
  return (
    <MainMenuWraper>
      <MenuItemsWrapper>
        <MenuItems pages={pages} token={auth.token} onClose={onClose} isMobile={true} />
        <Box mt="1rem" mb="1rem">
          <LinkButton
            onClick={moveToCategories}
            fontStyle="normal"
            className="menu-item"
            fontWeight='400'
            styles={css`
              margin: 0;
            `}
          >
            Genrer & kategorier
          </LinkButton>
        </Box>

        <Box mt="1rem" mb="1rem">
          {auth.token ? (
            <LinkButton
              onClick={async () => {
                await axios.post<boolean>('/api/auth/cookie', { name: 'auth', value: '' });
                accountDispatch({ type: accountActionTypes.LOGOUT });
                onClose();
                router.replace('/');
              }}
              className="menu-item"
              fontWeight='400'
              fontStyle="normal"
            >
              Logga ut
            </LinkButton>
          ) : (
            <LinkButton
              onClick={() => {
                onClose();
                dialogDispatch({ type: dialogActionTypes.DIALOG_AUTH, payload: { skipOffers: true } });
              }}
              className="menu-item"
              fontStyle="normal"
              fontWeight='400'
            >
              Logga in
            </LinkButton>
          )}
        </Box>
        
        <Box mt="1rem" mb="1rem">
          {(!isActiveSubscriber || !auth?.token) && (
            <Button href="/offers" size="small" color="white" openInNewTab={false} onClick={()=>{console.log('Close'); onClose();}}>
              {!auth?.token ? 'Gå med' : 'Börja titta'}
            </Button>
          )}
        </Box>
      </MenuItemsWrapper>

      <div>
        <Divider style={{ margin: '1rem 0' }} />
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {footerNav.map((page, index) => (
            <Box key={index} m="1rem">
              <Link href={page?.href ? page.href : `/${page.slug}`} onClick={onClose}>
                <Typography variant="labelSmall" className="menu-item">
                  {page.label}
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </div>
    </MainMenuWraper>
  );
};

export default MainMenu;
