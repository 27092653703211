import SearchIcon from '@/assets/icons/DF-search_icon-white.svg';
import SearchIconRed from '@/assets/icons/DF-search_icon-red.svg';
import { useState } from 'react';

const SearchIconWhite = () => {
  const [isHovered, setIsHovered] = useState(false);

  return <img src={isHovered ? SearchIconRed.src : SearchIcon.src} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} alt="search icon" style={{ width: '100%', height: '100%' }} />;
};

export default SearchIconWhite;
