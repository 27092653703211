import { IArticle } from '@/models/IArticle.interface';
import { IViewable } from '@/models/IViewable.interface';

type PageType = string | null;

export const globalSettings = (userId: string, loginState: string) => {
  window.dataLayer.push({
    hitTimestamp: new Date(),
    userId,
    loginState,
  });
};

export const pageView = (pageType: PageType) => {
  window.dataLayer.push({
    event: 'pageView', // Statiskt, event vid ny sidvisning
    pageType, // Dynamisk, namnet på sidan man är på (URL?)
  });
};

export const movieClick = (movie?: IViewable | null, pageType?: PageType, collection?: string | null) => {
  window.dataLayer.push({
    event: 'movieClick',
    movieTitle: movie?.title,
    movieGenre: movie?.genres,
    movieDirector: movie?.directors,
    movieYear: movie?.productionYear,
    movieLength: movie?.duration ?? 0,
    movieCountry: movie?.countriesOfOrigin,
    movieCollection: collection,
    pageType,
  });
};

export const articleClick = (arcicle: IArticle, articlePosition: number, pageType: PageType) => {
  window.dataLayer.push({
    event: 'articleClick',
    articleName: arcicle.title,
    articleCategory: arcicle.category.title,
    articlePosition: `slot ${articlePosition}`,
    pageType,
  });
};

export const buttonClick = (buttonName: string, pageType: PageType, title?: string | null) => {
  const getContent = (name: string) => {
    switch (name) {
      case 'promoClick':
        return {
          promoTitle: title,
        };
      case 'addToFavourites':
        return {
          movieTitle: title,
        };
      case 'removeFromFavourites':
        return {
          movieTitle: title,
        };
      case 'faqClick':
        return {
          questionType: title,
        };
      case 'appDownloadClick':
        return {
          appType: title,
        };
      default:
        return {};
    }
  };

  const eventObject = {
    event: 'buttonClick',
    buttonName,
    ...getContent(buttonName),
    pageType,
  };
  window.dataLayer.push(eventObject);
};

export const startaPrenumerationClick = (position: string, pageType: PageType) => {
  window.dataLayer.push({
    event: 'startaPrenumerationClick',
    position,
    pageType,
  });
};

export const cancelSubscriptionClick = (pageType: PageType) => {
  window.dataLayer.push({
    event: 'cancelSubscriptionClick',
    pageType,
  });
};

export const userRegistration = (pageType: PageType) => {
  window.dataLayer.push({
    event: 'userRegistration',
    registrationSuccess: 'TRUE',
    pageType,
  });
};

export const userForgottPassword = (pageType: PageType) => {
  window.dataLayer.push({
    event: 'userForgottPassword',
    pageType,
  });
};

export const userLogin = (pageType: PageType) => {
  window.dataLayer.push({
    event: 'userLogin',
    loginSuccess: true,
    pageType,
  });
};

export const activateSubscription = () => {
  window.dataLayer.push({
    event: 'activateSubscription',
  });
};

export const loginCreateAccount = () => {
  window.dataLayer.push({
    event: 'loginCreateAccount',
  });
};
export const orderComplete = (userId?: string | null) => {
  window.dataLayer.push({
    event: 'orderComplete',
    ...(userId ? { userId } : {}),
  });
};

export const subscriptionTermsApproval = (userTermApprove: boolean) => {
  window.dataLayer.push({
    event: 'subscriptionTermsApproval',
    userTermApprove,
  });
};

export const articleDetail = (article: IArticle, pageType: PageType) => {
  window.dataLayer.push({
    event: 'articleDetail',
    articleName: article?.title || '',
    articleCategory: article.category?.title || '',
    related_movies: article?.related_movieIds || [],
    related_articles: article?.related_articles || [],
    pageType,
  });
};

export const articleImpression = (articles: IArticle[], pageType: PageType) => {
  window.dataLayer.push({
    event: 'articleImpression',
    articleImpressions: articles.map((a, idx) => {
      return { articleTitle: a.title, articlePosition: `slot ${idx}` };
    }),
    pageType,
  });
};

export const startMovieClick = (movieTitle: string) => {
  window.dataLayer.push({
    event: 'startMovieClick',
    movieTitle,
  });
};

export const moviePlay = (event: { currentTime: Date }, movieTitle: string) => {
  window.dataLayer.push({
    event: 'moviePlay',
    currentTime: event.currentTime,
    movieTitle,
  });
};

export const moviePause = (movieTitle: string) => {
  window.dataLayer.push({
    event: 'moviePause',
    movieTitle,
  });
};

export const articleSearch = (stringValue: string, hits: Array<{ title: string; category: { title: string } }>) => {
  window.dataLayer.push({
    event: 'articleSearch',
    searchType: 'article',
    stringValue,
    success: hits.length > 0,
    hits: hits.map((hit) => {
      if (hit) {
        return {
          resultTitle: hit.title,
          resultCategory: hit.category.title,
        };
      }
      return {};
    }),
  });
};

export const movieSearch = (query: string, hits: IViewable[] = []) => {
  window.dataLayer.push({
    event: 'movieSearch',
    searchType: 'movie',
    stringValue: query,
    director: hits.map((hit) => (hit?.directors ? hit.directors : '')),
    genre: hits.map((hit) => (hit?.genres ? hit.genres : '')),
    country: hits.map((hit) => (hit?.countriesOfOrigin ? hit.countriesOfOrigin : '')),
    success: hits.length > 0,
    hits: hits.map((hit) => {
      if (hit) {
        return {
          resultTitle: hit?.title,
          resultCategory: '',
        };
      }
      return {};
    }),
  });
};

export const movieImpression = (hits: IViewable[] = []) => {
  window.dataLayer.push({
    event: 'movieImpression',
    movieCategory: hits.map((hit, idx) => {
      if (hit) {
        return {
          movieName: hit.title,
          moviePosition: `slot ${idx}`,
        };
      }
      return {};
    }),
  });
};

export const movieDetail = (movieName?: string | null, articles?: IArticle[]) => {
  window.dataLayer.push({
    event: 'movieDetail',
    movieName,
    relatedMovies: [],
    relatedArticle: articles?.map((article, idx) => {
      return {
        articleTitle: article.title,
        articlePostition: `slot ${idx}`,
      };
    }),
  });
};
