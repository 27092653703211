import { Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { FC, useCallback, useState } from 'react';

import Payment from '@/components/Payment';
import PaymentErrorMessage from '@/components/PaymentErrorMessage';
import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { PaymentType } from '@/models/Payment.enum';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Radikal-medium',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

interface PaymentDialogProps {
  type?: PaymentType;
  result?: AdyenPaymentResultCode;
  offerId?: string;
  promoCode?: string;
}

export const PaymentDialogStep: FC<PaymentDialogProps> = ({
  type,
  result,
  offerId: _offerId,
  promoCode: _promoCode,
}) => {
  const classes = useStyles();

  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  let title: string | undefined;
  if (type === PaymentType.SavePayment) {
    title = 'Uppdatera betaluppgifter';
  } else if (result === AdyenPaymentResultCode.Authorised) {
    title = 'Välkommen till ditt nya filmliv!';
  }

  // Don't include offerId and promoCode when payment already successful
  const offerId = result !== AdyenPaymentResultCode.Authorised ? _offerId : undefined;
  const promoCode = result !== AdyenPaymentResultCode.Authorised ? _promoCode : undefined;

  return (
    <>
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}
      <PaymentErrorMessage error={error} />
      <Payment type={type} result={result} offerId={offerId} promoCode={promoCode} onError={handleError} />
    </>
  );
};
