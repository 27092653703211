import { Box } from '@material-ui/core';
import { FC } from 'react';

import { Typography } from '@/components/Typography';
import { IAuthUser } from '@/models/IAuthUser.interface';

import { TextButton } from '../../AuthorizationDialog.styles';

interface SelectOfferStepProps {
  formEmail: string;
  auth: IAuthUser;
  goToPreviousStep: () => void;
}

const SelectOfferStep: FC<SelectOfferStepProps> = ({ formEmail, auth, goToPreviousStep }) => {
  return (
    <Box textAlign="center">
      {formEmail ? 'Du loggar in som:' : 'Du är inloggad som:'}
      <Box>
        <strong>{auth.openId ?? formEmail}</strong>
      </Box>
      <TextButton onClick={goToPreviousStep}>
        <Typography variant="bodySmall">Inte din epost? Gå tillbaka</Typography>
      </TextButton>
    </Box>
  );
};

export default SelectOfferStep;
