import { css } from '@emotion/react';
import Link from 'next/link';
import { FC, useRef } from 'react';

import { ButtonProps, StartIconWrapper, StyledButton } from './utils';

import { Typography } from '../Typography';

const Button: FC<ButtonProps> = ({
  children,
  href = '',
  onClick = () => null,
  size = 'small',
  color = 'black',
  dataTestId,
  disabled = false,
  startIcon,
  fullWidth = false,
  openInNewTab = true,
  multiply = false,
}) => {
  const ref = useRef(null);

  return (
    <div>
      {!href ? (
        <StyledButton
          ref={ref}
          size={size}
          onClick={onClick}
          color={color}
          data-testid={dataTestId}
          disabled={disabled}
          fullWidth={fullWidth}
          multiply={multiply}
        >
          {startIcon && <StartIconWrapper color={color}>{startIcon}</StartIconWrapper>}
          {children}
        </StyledButton>
      ) : (
        <Link href={href} target={openInNewTab ? '_blank' : '_self'}>
          <StyledButton size={size} color={color} data-testid={dataTestId} disabled={disabled} multiply={multiply} onClick={onClick}>
            {startIcon && <StartIconWrapper color={color}>{startIcon}</StartIconWrapper>}
            <Typography
              variant="labelLarge"
              fontWeight="500"
              styles={css`
                letter-spacing: 1px;
                text-transform: uppercase;
                color: inherit;
                font-size: 16px;
              `}
            >
              {children}
            </Typography>
          </StyledButton>
        </Link>
      )}
    </div>
  );
};

export { Button };
