import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import DFLogoGray from '@/assets/footer/DF_logo_gray.svg';
import BackgroundLogo from '@/assets/footer/footer.svg';
import { useNavigation } from '@/context/NavigationContext';
import { useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { toAdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { PaymentType } from '@/models/Payment.enum';

import BasicContainer from './Containers/BasicContainer';

import { media } from '@/styles';

interface StyledFooterProps {
  backgroundColor?: string;
  backgroundUrl: string;
}

const StyledFooter = styled.div<StyledFooterProps>`
  background: ${({ backgroundColor, theme }) => backgroundColor ?? theme.palette.background.default}
    ${({ backgroundUrl }) => `url("${backgroundUrl}")`} repeat scroll center;
`;

const StyledNextLink = styled(Link)`
  color: #8e8e8e;
  margin: 1rem;
  padding: 0 1rem;

  :hover {
    color: #ec6446;
  }
`;

const StyledParagraph = styled.p`
  color: #8e8e8e;
  text-align: center;
  padding: 0 2rem 10rem 2rem;
  font-size: 16px;
`;

const LinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10rem 0;

  ${media.sm} {
    flex-direction: row;
  }
`;

const HoverLink = styled.span`
  :hover {
    color: #ec6446;
  }
`;

const Footer: FC = () => {
  const router = useRouter();
  const dialogDispatch = useDialogDispatch();
  const { auth } = useAccountState();

  const { footerNav } = useNavigation();

  useEffect(() => {
    if (router.query.reset_password_token) {
      dialogDispatch({ type: dialogActionTypes.DIALOG_RESET_PASSWORD });
    }

    if (router.query.authResult) {
      const result = toAdyenPaymentResultCode(router.query.authResult.toString());
      if (result) {
        const { savePayment, offerId, promoCode } = router.query;
        dialogDispatch({
          type: dialogActionTypes.DIALOG_PAYMENT,
          payload: {
            type: savePayment ? PaymentType.SavePayment : PaymentType.MakePayment,
            result,
            offerId: offerId?.toString() ?? undefined,
            promoCode: promoCode?.toString() ?? undefined,
          },
        });
        // Clear URL search params after payment dialog is shown
        const originalUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, originalUrl);
      }
    }
  }, [router, dialogDispatch]);

  return (
    <StyledFooter backgroundUrl={BackgroundLogo.src} backgroundColor="#000000">
      <BasicContainer>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
          <LinkList>
            {footerNav.map((page, idx) =>
              page.isPrivate ? (
                auth.token && (
                  <StyledNextLink key={idx} href={page?.href ? page.href : `/${page.slug}`}>
                    {page.label}
                  </StyledNextLink>
                )
              ) : (
                <StyledNextLink key={idx} href={page?.href ? page.href : `/${page.slug}`}>
                  {page.label}
                </StyledNextLink>
              )
            )}
          </LinkList>
          <Link href="https://goteborgfilmfestival.se/" target="_blank">
            <Box display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
              <Box mb={2}>
                <img src={DFLogoGray.src} />
              </Box>
              
              <StyledParagraph>
                <HoverLink>
                  Draken&nbsp;Film&nbsp;är&nbsp;en&nbsp;del&nbsp;av Göteborg&nbsp;Film&nbsp;Festival.
                </HoverLink>
              </StyledParagraph>
            </Box>
          </Link>
        </Box>
      </BasicContainer>
    </StyledFooter>
  );
};

export default Footer;
