import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form';

import EyeAdornment from '@/components/EyeAdornment/EyeAdornment';
import { Typography } from '@/components/Typography';

import { AuthForm } from '../../AuthorizationDialog.types';
import { mqMax } from '@/styles/breakpoints';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const MINIMUM_PASSWORD_LENGTH = 6;

interface RegistrationStepProps {
  control: Control<AuthForm>;
  errors: FieldErrors<AuthForm>;
  getValues: UseFormGetValues<AuthForm>;
}

const FieldWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;

const RegistrationStep: FC<RegistrationStepProps> = ({ control, errors, getValues }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div data-testid="register-dialog">
      <Box mb={5}>
        <Box mb={4}>
        <Typography variant="titleMedium" fontWeight='500' styles={css`
            font-size: 28px;
            padding-bottom: 1rem;
            ${mqMax[3]} {
              font-size: 28px;
            }
            ${mqMax[4]} {
              font-size: 28px;
            }
          `}>Skapa konto</Typography>
        </Box>
        <Box mb={8}>
          <Typography variant="bodySmall">
            Fortsätt genom att välja ett lösenord och godkänna användarvillkoren:
          </Typography>
        </Box>
        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Obligatoriskt fält',
            validate: (value) =>
              value.length < MINIMUM_PASSWORD_LENGTH ? 'Lösenordet måste vara minst 6 tecken långt' : true,
          }}
          render={({ field }) => (
            <FieldWrapper>
              <TextField
                {...field}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                margin="dense"
                id="accessKey"
                placeholder="Lösenord"
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <EyeAdornment
                      handleClickShowPassword={toggleShowPassword}
                      handleMouseDownPassword={toggleShowPassword}
                      showPassword={showPassword}
                    />
                  ),
                }}
              />
            </FieldWrapper>
          )}
        />
      </Box>
      <Box mb={5}>
        <Controller
          name="repeatPassword"
          control={control}
          rules={{
            required: 'Obligatoriskt fält',
            validate: (value) => (value !== getValues('password') ? 'Lösenorden matchar inte' : true),
          }}
          render={({ field }) => (
            <FieldWrapper>
              <TextField
                {...field}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                margin="dense"
                id="accessKeyRepeat"
                placeholder="Upprepa lösenord"
                error={!!errors.repeatPassword}
                helperText={errors.repeatPassword?.message}
              />
            </FieldWrapper>
          )}
        />
      </Box>
      <Box>
        <FormControl error={!!errors.terms}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                rules={{ required: 'Obligatoriskt fält' }}
                render={({ field }) => <Checkbox {...field} name="terms" color="primary" />}
                name="terms"
              />
            }
            label="Jag godkänner användarvillkoren *"
          />
          {errors.terms && <FormHelperText>{errors.terms.message}</FormHelperText>}
        </FormControl>
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name="marketing"
              render={({ field }) => <Checkbox {...field} color="primary" />}
            />
          }
          label="Jag vill ha filmtips! Jag godkänner att min e-postadress sparas för utskick av filmtips och påminnelser"
        />
      </Box>
    </div>
  );
};

export default RegistrationStep;
